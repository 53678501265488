import React, { Component } from 'react';
import { Container } from '@mui/material';

// Components
import { TrainingLibraryWidget } from '../../../components';

class Library extends Component {
  render() {
    return (
      <Container style={{ marginTop: '10px' }}>
        <TrainingLibraryWidget height={window.innerHeight - 280} />
      </Container>
    );
  }
}

export default Library;
