import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';

// Components
import  { Button, Table } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { listGroupEvaluations, getGroup } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';

const COLUMNS = [
  {
    title: 'View',
    render: rowData => (
      <Link to={'/evaluations/' + rowData.getId()}><VisibilityIcon /></Link>
    ),
  },
  {
    title: 'Evaluator',
    render: rowData => rowData?.getEvaluator()?.getFullName(),
    customFilterAndSearch: (term, rowData) => rowData?.getEvaluator()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getEvaluator()?.getFullName()?.localeCompare(b.getEvaluator()?.getFullName()),
  },
  {
    title: 'Evaluee',
    render: rowData =>
      rowData?.getEvaluee()?.getFullName(),
    customFilterAndSearch: (term, rowData) => rowData?.getEvaluee()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getEvaluee()?.getFullName()?.localeCompare(b.getEvaluee()?.getFullName()),
  },
  {
    title: 'Segment',
    render: rowData => rowData?.getSegment()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getSegment()?.getName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getSegment()?.getName()?.localeCompare(b.getSegment()?.getName()),
  },
  {
    title: 'Status',
    render: rowData => rowData?.getStatus(),
    customFilterAndSearch: (term, rowData) => rowData?.getStatus()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getStatus()?.localeCompare(b.getStatus()),
  },
  {
    title: 'Scheduled Date/Time',
    render: rowData => rowData?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME),
    customFilterAndSearch: (term, rowData) => rowData?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME).toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getScheduledDateTime() - b.getScheduledDateTime(),
  },
  {
    title: 'Location',
    render: rowData => rowData?.getAddress() ? (rowData?.getAddress()?.getCity() + ', ' + rowData?.getAddress()?.getState()) : null,
    customFilterAndSearch: (term, rowData) => (rowData?.getAddress()?.getCity()?.toLowerCase() + ', ' + rowData?.getAddress()?.getState()?.toLowerCase())?.includes(term?.toLowerCase())
  },
];

class GroupEvaluations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      evaluations: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch(error => {
      setError(error ?? 'Error: Could not load group');
    });

    this.setState({ isLoading: true });
    listGroupEvaluations({ groupId }).then(({ results }) => {
      this.setState({ evaluations: results, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve group evaluations.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>{this.state.group?.getName()} Evaluations</Typography>
        <Table
          title={<Button component={Link} to={'/evaluations/schedule?groupId=' + this.props.match?.params?.id} style={{ width: 200 }}>Schedule New</Button>}
          data={this.state.evaluations}
          columns={COLUMNS}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

GroupEvaluations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GroupEvaluations);
