import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { CircularProgress, Container } from '@mui/material';

// Server
import { changeEmailConfirm } from '../../../api';

// Alerts
import { setError, setSuccess } from '../../../alerts';

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      emailConfirmed: false,
    };
  }

  componentDidMount() {
    const token = this.props.match?.params?.token;

    changeEmailConfirm(token)
      .then(() => {
        setSuccess('Email confirmed!');
        this.setState({ isLoading: false, emailConfirmed: true });
      })
      .catch((error) => {
        setError(error ?? 'Error: Could not confirm email.');
        this.setState({ isLoading: false, emailConfirmed: false });
      });
  }

  render() {
    if (!this.state.isLoading)
      return <Redirect to="/" />;

    return (
      <Container style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {this.state.isLoading && <CircularProgress size={100} style={{ marginLeft: '1em' }} />}
      </Container>
    );
  }
}

ConfirmEmail.propTypes = {
  match: PropTypes.func.isRequired
};

export default withRouter(ConfirmEmail);
