import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// API
import { getEvaluator, getSegment, getPerson } from '../../../../../../api';

// Redux
import { connect } from 'react-redux';

// Alerts
import { setError, clearErrors } from '../../../../../../alerts';

// Icons
import { FaUserCircle, FaPencilRuler } from 'react-icons/fa';

// Components
import StepSection from '../StepSection';

// Styling
const styles = theme => ({
  pageTitle: {
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '1.5em'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  hoverText: {
    textAlign: 'center',
    marginTop: '1em'
  }
});

const HOVER_TEXT = [
  'We ask you to create your account and set up two-factor authentication in order to ensure you are a real human.',
  'Complete our proprietary, interactive training modules to prepare you to become a certified Ergonaut™ Evaluator.',
  'Provide us with additional personal information on your certifications and skills and let us know how far you are willing to travel to perform evaluations.'
];

class GroupSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segment: null,
      isEvaluatorProfileComplete: false,
      hoverIndex: null,
      redirect: false,
      isLoadingSegment: false,
      isLoadingEvaluator: false
    };
  }

  componentDidMount() {
    const segmentId = this.props.match?.params?.id;

    // Retrieve segment information
    this.setState({ isLoadingSegment: true });
    getSegment(segmentId).then(segment => {
      this.setState({ isLoadingSegment: false, segment: segment });
    }).catch(error => {
      this.setState({ isLoadingSegment: false });
      setError(error ?? 'Error: Unable to retrieve segment info.');
    });

    // Check if evaluator profile is complete already
    this.setState({ isLoadingEvaluator: true });
    getEvaluator().then((evaluator) => {
      this.setState({ isLoadingEvaluator: false, isEvaluatorProfileComplete: evaluator.workAddressId != null });
    }).catch(() => {
      this.setState({ isLoadingEvaluator: false });
    });

    if (this.props.isFullyAuthenticated) {
      this.loadPerson();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFullyAuthenticated != this.props.isFullyAuthenticated && this.props.isFullyAuthenticated) {
      this.loadPerson();
    }
  }

  componentWillUnmount() {
    clearErrors();
  }

  loadPerson() {
    getPerson().then(person => {
      this.setState({ person: person }, () => this.redirectIfOnlyIndependentEvaluator());
    }).catch(error => setError(error ?? 'Error: Unable to load user information.'));
  }

  redirectIfOnlyIndependentEvaluator() {
    var isOnlyIndependentEvaluator = true;
    this.state.person?.getGroupAssociations()?.forEach(groupAssociation => {
      if (!groupAssociation.getGroup()?.getIsIndependent() && groupAssociation.getRole()?.getRole() == 'ERGONAUT_EVALUATOR') {
        isOnlyIndependentEvaluator = false;
      }
    });
    this.setState({ redirect: isOnlyIndependentEvaluator });
  }

  render() {
    const { classes } = this.props;

    if (this.state.redirect) {
      return <Redirect to='/' />;
    }

    return (
      <Container>
        <Typography variant='h1' className={classes.pageTitle}>Become an {this.state.segment?.getName()} Evaluator</Typography>

        {/* Step Sections */}
        <Grid container className={classes.cardContainer} columnSpacing={3} rowSpacing={3}>
          <StepSection step={1} name='Establish An Account' to='/account/account-settings' completed hoverText={HOVER_TEXT[0]} />
          <StepSection
            step={2}
            name='Complete Your Training'
            to='/training/training-module/1'
            Icon={FaPencilRuler}
            completed={this.state.segment?.getHasMetSegmentRequirements()}
            hoverText={HOVER_TEXT[1]}
            isLoading={this.state.isLoadingSegment}
          />
          <StepSection
            step={3}
            name='Complete Your Evaluator Profile'
            to='/evaluator/create-profile'
            Icon={FaUserCircle}
            completed={this.state.isEvaluatorProfileComplete}
            hoverText={HOVER_TEXT[2]}
            isLoading={this.state.isLoadingEvaluator}
          />
        </Grid>
      </Container>
    );
  }
}

GroupSegment.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isFullyAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isFullyAuthenticated: state.auth.isFullyAuthenticated
});

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(withRouter(GroupSegment)));
