// eslint-disable-next-line no-undef
const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
const LANDING_URL = 'https://www.ergonauts.com';

// Container for all elements to be rendered in the App
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import { AlertList, ConfirmationModal } from '../components';
import PageWrapper from './PageWrapper';

// Pages
import CreateAccount from '../pages/auth/create-account';
import Login from '../pages/auth/login';
import TwoFactor from '../pages/auth/two-factor';
import TwoFactorSetup from '../pages/auth/two-factor/setup';
import RequestPasswordReset from '../pages/auth/request-password-reset';
import ConfirmPasswordReset from '../pages/auth/confirm-password-reset/[token]';
import ConfirmEmail from '../pages/auth/confirm-email/[token]';
import ConfirmAccount from '../pages/auth/confirm-account/[token]';

import CreateProfile from '../pages/evaluator/create-profile';
import Segments from '../pages/evaluator/segments';
import Segment from '../pages/evaluator/segments/[id]';
import SegmentSignUp from '../pages/evaluator/segments/[id]/steps';
import PurchaseSegment from '../pages/evaluator/segments/[id]/purchase';
import SegmentCertification from '../pages/evaluator/segments/[id]/certification';
import GroupSegment from '../pages/evaluator/segments/[id]/steps/group';

import Checkr from '../pages/evaluator/checkr';
import EvaluatorEvaluations from '../pages/evaluator/evaluations';

import EvalueeEvaluations from '../pages/evaluee/evaluations';

import Group from '../pages/groups/[id]';
import GroupEvaluators from '../pages/groups/[id]/evaluators';
import GroupAdmins from '../pages/groups/[id]/admins';
import GroupEvaluations from '../pages/groups/[id]/evaluations';
import GroupInvitations from '../pages/groups/[id]/invitations';

import RequestEvaluation from '../pages/landing/request-eval';

import EvaluationInvitation from '../pages/invitations/evaluation/[token]';
import GroupInvitation from '../pages/invitations/group/[token]';

import AccountSettings from '../pages/account/account-settings';
import MyBilling from '../pages/account/billing';

import Evaluation from '../pages/evaluations/[id]';
import ScheduleEvaluation from '../pages/evaluations/schedule';
import EvaluatorCertifications from '../pages/evaluator/certifications';

import Ergonar from '../pages/training/ergonar/[id]';
import Library from '../pages/training/library';
import TrainingModule from '../pages/training/training-module/[id]';
import UpcomingErgonars from '../pages/training/upcoming-ergonars';
import Training from '../pages/training';

import HomeRedirect from './HomeRedirect';

function RouteAndWrapper({ children, path }) {
  return (
    <Route path={path}>
      <PageWrapper>
        {children}
      </PageWrapper>
    </Route>
  );
}
RouteAndWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired
};

export default class Navigation extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '120px' }}>
        <AlertList />
        <ConfirmationModal />
        <Switch>
          <RouteAndWrapper path='/evaluations/schedule' exact>
            <ScheduleEvaluation />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluations/:id' exact>
            <Evaluation />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/training-module/:id' exact>
            <TrainingModule />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/ergonar/:id' exact>
            <Ergonar />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/library' exact>
            <Library />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/upcoming-ergonars' exact>
            <UpcomingErgonars />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/' exact>
            <Training />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training'>
            <Redirect to='/training' />
          </RouteAndWrapper>
          <RouteAndWrapper path='/account/account-settings' exact>
            <AccountSettings />
          </RouteAndWrapper>
          <RouteAndWrapper path='/account/billing' exact>
            <MyBilling />
          </RouteAndWrapper>

          <RouteAndWrapper path='/evaluator/certifications' exact>
            <EvaluatorCertifications />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/checkr' exact>
            <Checkr />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/segments/:id/steps' exact>
            <SegmentSignUp />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/segments/:id/purchase' exact>
            <PurchaseSegment />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/segments/:id/certification' exact>
            <SegmentCertification />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/segments/:id' exact>
            <Segment />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/segments' exact>
            <Segments />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/segments/:id/steps/group' exact>
            <GroupSegment />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/create-profile' exact>
            <CreateProfile />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluator/evaluations' exact>
            <EvaluatorEvaluations />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluee/evaluations' exact>
            <EvalueeEvaluations />
          </RouteAndWrapper>

          <RouteAndWrapper path='/groups/:id' exact>
            <Group />
          </RouteAndWrapper>
          <RouteAndWrapper path='/groups/:id/evaluators' exact>
            <GroupEvaluators />
          </RouteAndWrapper>
          <RouteAndWrapper path='/groups/:id/admins' exact>
            <GroupAdmins />
          </RouteAndWrapper>
          <RouteAndWrapper path='/groups/:id/evaluations' exact>
            <GroupEvaluations />
          </RouteAndWrapper>
          <RouteAndWrapper path='/groups/:id/invitations' exact>
            <GroupInvitations />
          </RouteAndWrapper>

          <RouteAndWrapper path='/landing/request-eval' exact>
            <RequestEvaluation />
          </RouteAndWrapper>

          <RouteAndWrapper path='/invitations/evaluation/:token' exact>
            <EvaluationInvitation />
          </RouteAndWrapper>
          <RouteAndWrapper path='/invitations/group/:token' exact>
            <GroupInvitation />
          </RouteAndWrapper>

          <RouteAndWrapper path='/auth/login/redirect' exact>
            <Redirect to='/' />
          </RouteAndWrapper>
          <RouteAndWrapper path='/auth/create-account' exact>
            <CreateAccount />
          </RouteAndWrapper>
          <RouteAndWrapper path='/auth/two-factor' exact>
            <TwoFactor />
          </RouteAndWrapper>
          <RouteAndWrapper path='/auth/two-factor/setup' exact>
            <TwoFactorSetup />
          </RouteAndWrapper>
          <RouteAndWrapper path='/auth/login' exact>
            <Login />
          </RouteAndWrapper>
          <RouteAndWrapper path='/auth/forgot-password' exact>
            <RequestPasswordReset />
          </RouteAndWrapper>
          <RouteAndWrapper path="/auth/confirm-password-reset/:token" exact>
            <ConfirmPasswordReset />
          </RouteAndWrapper>
          <RouteAndWrapper path="/auth/confirm-email/:token" exact>
            <ConfirmEmail />
          </RouteAndWrapper>
          <RouteAndWrapper path="/auth/confirm-account/:token" exact>
            <ConfirmAccount />
          </RouteAndWrapper>

          <Route path='/admin' exact component={() => {
            window.location.href = ADMIN_URL + '/people';
            return null;
          }} />

          <Route path='/landing' exact component={() => {
            window.location.href = LANDING_URL;
            return null;
          }} />

          <RouteAndWrapper path='/'>
            <HomeRedirect />
          </RouteAndWrapper>
        </Switch>
      </div>
    );
  }
}
