import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

// Components
import { Button } from '@lexcelon/react-util';

//API
import { getTokenGroupInvitation, acceptGroupInvitation, declineGroupInvitation } from '../../../../api';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// Images
import { ColorLogo } from '@ergonauts/ergo-algo-react/core/images';

import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';


class GroupInvitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      groupAssociation: null,
      isLoading: false,
      isAcceptLoading: false,
      isRejectLoading: false
    };
  }

  componentDidMount() {
    var invitationToken = this.props.match?.params?.token;
    getTokenGroupInvitation(invitationToken).then(groupAssociation => {
      this.setState({ groupAssociation: groupAssociation });
    }).catch(error => {
      this.setState({ redirect: true });
      setError(error ?? 'Error: Unable to retrieve invitation');
    });
  }

  acceptGroupInvitation() {
    var invitationToken = this.props.match?.params?.token;
    this.setState({ isLoading: true, isAcceptLoading: true });
    acceptGroupInvitation(invitationToken).then(() => {
      setSuccess('Successfully accepted invitation!');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ?? 'Error: could not accept invitation');
      this.setState({ isLoading: false });
    });
  }

  declineGroupInvitation() {
    var invitationToken = this.props.match?.params?.token;
    this.setState({ isLoading: true, isRejectLoading: true });
    declineGroupInvitation(invitationToken).then(()=> {
      setSuccess('Successfully declined invitation!');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ?? 'Error: could not decline invitation');
      this.setState({ isLoading: false });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/'} />;
    }

    return (
      <Container>
        <form autoComplete="off" style={{ marginBottom: '5em' }}>
          {/* ----- LOGO & TITLE ----- */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em', marginBottom: '1em' }}>
            {ColorLogo != null &&
            <img
              width={250}
              src={ColorLogo}
              alt={'Logo'}
              title={'Logo'}
              style={{ objectFit: 'contain' }}
            />}
          </div>

          {/* ---- DISPLAY TEXT ---- */}
          <Typography variant='h2'>
            You have been invited to join the {this.state.groupAssociation?.getGroup()?.getName()} group as an {enumToDisplayString(this.state.groupAssociation?.getRole()?.getRole())}. Please accept or deny this invitation using the buttons below.
          </Typography>

          {/* ----- BUTTON CONFIRMATION ----- */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
            <Button disabled={this.state.isLoading} isLoading={this.state.isAcceptLoading} style={{ width: '49%', marginRight: '10px' }} onClick={() => this.acceptGroupInvitation()}>
              Yes, I accept
            </Button>
            <Button outlined disabled={this.state.isLoading} isLoading={this.state.isRejectLoading} style={{ width: '49%', marginRight: '10px' }} onClick={() => this.declineGroupInvitation()}>
              No, I do not
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

GroupInvitation.propTypes = {
  match: PropTypes.object.isRequired,
};

export default (withRouter(GroupInvitation));
