import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid, CircularProgress, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Icons
import { FaCheckCircle } from 'react-icons/fa';

// Constants
import { ERGO_BLUE, BLUE, GROOVY_GREEN, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

// Styling
const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '225px',
    borderRadius: 5,
    background: 'linear-gradient(180deg, ' + ERGO_BLUE + ' 0%, ' + BLUE + ' 100%)',
    padding: '25px 15px 25px 15px'
  },
  stepNumberDiv: {
    backgroundColor: TEXT_WHITE,
    borderRadius: '75px',
    margin: '10px 0px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepNumberText: {
    color: ERGO_BLUE,
    fontWeight: 'bold'
  },
  stepNameDiv: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepNameText: {
    color: TEXT_WHITE,
    margin: '15px',
    textAlign: 'center',
    personSelect: 'none'
  },
  iconDiv: {
    margin: '5px 0px 10px 0px'
  }
});

// Step Section Component
class StepSection extends Component {
  render() {
    const Icon = this.props.Icon;
    const { classes } = this.props;
    return (
      <Tooltip title={this.props.hoverText}>
        <Grid item>
          <Link to={this.props.disabled || this.props.completed || this.props.isLoading ? '#' : this.props.to} style={{ textDecoration: 'none', pointerEvents: this.props.disabled || this.props.completed || this.props.isLoading ? 'none' : 'auto' }}>
            <Box className={classes.mainContainer} sx={{ height: { xs: '250px', sm: '425px' } }}>
              {/* Step Number */}
              <Box className={classes.stepNumberDiv} sx={{ width: { xs: '75px', sm: '150px' }, height: { xs: '75px', sm: '150px' } }}>
                <Typography variant='h2' className={classes.stepNumberText} sx={{ fontSize: { xs: 45, sm: 75 } }}>{this.props.step}</Typography>
              </Box>

              {/* Step Name */}
              <div className={classes.stepNameDiv}>
                <Typography variant='body1' className={classes.stepNameText} sx={{ fontSize: { xs: 20, sm: 25 } }}>{this.props.name}</Typography>
              </div>

              {/* Icon */}
              <div className={classes.iconDiv}>
                {this.props.isLoading ? <CircularProgress style={{ fontSize: 50, color: 'white' }} /> : (this.props.completed ? <FaCheckCircle size={50} color={GROOVY_GREEN} /> : <Icon size={50} color={TEXT_WHITE} />)}
              </div>
            </Box>
          </Link>
        </Grid>
      </Tooltip>
    );
  }
}
StepSection.propTypes = {
  name: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  completed: PropTypes.bool,
  to: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  hoverText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(StepSection);
