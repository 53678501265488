import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Container, Paper, Typography } from '@mui/material';

// API
import { getSegment } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';

// Components
import { Button, LineItem } from '@lexcelon/react-util';

// Constants
import { LIGHT_GREY } from '@ergonauts/ergo-algo-react/core/constants';

class Segment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segment: null
    };
  }

  componentDidMount() {
    const segmentId = this.props.match?.params?.id;

    getSegment(segmentId).then(segment => {
      this.setState({ segment: segment });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve segment information.');
    });
  }

  render() {
    return (
      <Container style={{ marginBottom: '75px' }}>

        {/* Header */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '50px 0px 10px 0px' }}>
          <img src={this.state.segment?.getDisplayImageUrl()} style={{ width: '150px', height: 'auto', objectFit: 'contain', border: '1px black solid' }} />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '20px' }}>
            <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>{this.state.segment?.getName()}</Typography>
            <Button component={Link} to={'/evaluator/segments/' + this.props.match?.params?.id + '/steps'}>Become an Evaluator</Button>
          </div>
        </div>

        {/* Overview */}
        <Typography variant='h2' style={{ margin: '0.5em 0px 0.5em 0px' }}>Overview</Typography>

        <LineItem
          value={this.state.segment?.getLongDescription()}
          description={'Description'}
        />
        <LineItem
          value={this.state.segment?.getTrainingCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          description={'Per training and certification, per user'}
        />

        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Subscription Tiers</Typography>
        {this.state.segment?.getSubscriptionTiers()?.sort((a, b) => a.getMonthlyCost() - b.getMonthlyCost())?.map((subscriptionTier, index) => (
          <Paper key={index} elevation={2} style={{ padding: '15px 15px 5px 15px', marginBottom: '10px', backgroundColor: LIGHT_GREY }}>
            <Typography variant='h4' style={{ fontSize: 20, textTransform: 'uppercase', fontWeight: 'bold' }}>{subscriptionTier.getName()}</Typography>
            <LineItem
              value={subscriptionTier.getMonthlyCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' / mo'}
              description={'Evaluator subscription per month, per certification, billed monthly, auto-renewed'}
            />
            <LineItem
              value={subscriptionTier.getMaxNumEvaluations() + ' evaluations (avg. ' + (subscriptionTier.getMonthlyCost() != null && subscriptionTier.getMaxNumEvaluations() != 0 ? (subscriptionTier.getMonthlyCost() / subscriptionTier.getMaxNumEvaluations()).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : subscriptionTier.getAdditionalEvaluationCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })) + ' / evaluation)'}
              description={'Included Evaluations (Expires Monthly)'}
            />
            <LineItem
              value={subscriptionTier.getAdditionalEvaluationCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' / evaluation'}
              description={'Per Each Additional Evaluation'}
            />
          </Paper>
        ))}

        {/* Certification Requirements */}
        <Typography variant='h2' style={{ margin: '1em 0px 0.5em 0px' }}>Four-Step Process to Become a Certified Ergonaut™ Evaluator</Typography>
        <Typography variant='body1'><b>1. Establish an Account</b>: We ask you to create your account and set up two-factor authentication in order to ensure you are a real human.</Typography>
        <Typography variant='body1'><b>2. Purchase Training & Subscription</b>: Select and purchase the training you are interested in to become a certified Ergonaut™ Evaluator.</Typography>
        <Typography variant='body1'><b>3. Complete Your Training</b>: Complete our proprietary, interactive training modules to prepare you to become a certified Ergonaut™ Evaluator.</Typography>
        <Typography variant='body1'><b>4. Complete Your Evaluator Profile</b>: Provide us with additional personal information on your certifications and skills and let us know how far you are willing to travel to perform evaluations.</Typography>
      </Container>
    );
  }
}

Segment.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Segment);
