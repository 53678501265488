import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography, TextField, Link } from '@mui/material';

// Components
import { Button } from '@lexcelon/react-util';

// Server
import { confirmForgotPassword } from '../../../api';

// Alerts
import { setError, setSuccess } from '../../../alerts';

class ConfirmPasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      passwordResetConfirmed: false,
      newPassword: '',
      confirmNewPassword: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    // Make sure passwords match
    if (this.state.newPassword === '' || this.state.newPassword !== this.state.confirmNewPassword) {
      setError('Error: Passwords do not match.');
      return;
    }

    const token = this.props.match?.params?.token;


    this.setState({ isLoading: true });
    confirmForgotPassword({ token, password: this.state.newPassword })
      .then(() => {
        setSuccess('Password Successfully Reset!');
        this.setState({ isLoading: false, passwordResetConfirmed: true });
      })
      .catch((error) => {
        setError(error ?? 'Could not confirm.');
        this.setState({ isLoading: false, passwordResetConfirmed: false });
      });
  }

  render() {
    if (this.state.passwordResetConfirmed)
      return <Redirect to="/login" />;

    return (
      <Container>
        <div>
          <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
            {/* ----- CHANGE PASSWORD ----- */}
            <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Reset Password</Typography>

            {/* New Password Input */}
            <TextField
              required
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.newPassword === ''}
              name="newPassword"
              label="New Password"
              type='password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.newPassword}
              onChange={this.onChange}
              variant='filled'
            />

            {/* Confirm New Password Input */}
            <TextField
              required
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.confirmNewPassword === ''}
              name="confirmNewPassword"
              label="Confirm New Password"
              type='password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.confirmNewPassword}
              onChange={this.onChange}
              variant='filled'
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
              <Link href='/auth/login' style={{ width: '49%' }}>
                <Button secondary disabled={this.state.isLoading} onClick={this.exitChangePasswordMode} style={{ width: '100%' }}>
                  Cancel
                </Button>
              </Link>
              <Button type='submit' isLoading={this.state.isLoading} style={{ width: '49%' }}>
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

ConfirmPasswordReset.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ConfirmPasswordReset);
