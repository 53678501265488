import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

// Components
import { Button } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

//API
import { getTokenEvaluation, acceptEvaluationInvitation, rejectEvaluationInvitation } from '../../../../api';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// Images
import { ColorLogo } from '@ergonauts/ergo-algo-react/core/images';

class EvaluationInvitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answerRedirect: false,
      tokenRedirect: false,
      invitation: null,
      isLoading: false,
      isAcceptLoading: false,
      isRejectLoading: false
    };
  }

  componentDidMount() {
    getTokenEvaluation(this.props.match?.params?.token).then(invitation => {
      this.setState({ invitation: invitation });
    }).catch(error => {
      this.setState({ tokenRedirect: true });
      setError(error ?? 'Error: Unable to retrieve invitation');
    });
  }

  acceptEvaluationInvitation() {
    this.setState({ isLoading: true, isAcceptLoading: true });
    acceptEvaluationInvitation(this.props.match?.params?.token).then(() => {
      setSuccess('Successfully accepted invitation!');
      this.setState({ isLoading: false, answerRedirect: true });
    }).catch(error => {
      setError(error ?? 'Error: could not accept invitation');
      this.setState({ isLoading: false, isAcceptLoading: false });
    });
  }

  rejectEvaluationInvitation() {
    this.setState({ isLoading: true, isRejectLoading: true });
    rejectEvaluationInvitation(this.props.match?.params?.token).then(()=> {
      setSuccess('Successfully rejected invitation!');
      this.setState({ isLoading: false, answerRedirect: true });
    }).catch(error => {
      setError(error ?? 'Error: could not reject invitation');
      this.setState({ isLoading: false, isRejectLoading: false });
    });
  }

  render() {
    if (this.state.answerRedirect) { // if invalid token is sent
      return <Redirect to={'/auth/login'} />;
    }

    if (this.state.tokenRedirect) { // if either button is pressed
      return <Redirect to={'/auth/create-account'} />;
    }

    return (
      <Container>
        <form autoComplete="off" style={{ marginBottom: '5em' }}>
          {/* ----- LOGO & TITLE ----- */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em', marginBottom: '1em' }}>
            {ColorLogo != null &&
            <img
              width={250}
              src={ColorLogo}
              alt={'Logo'}
              title={'Logo'}
              style={{ objectFit: 'contain' }}
            />}
          </div>

          {/* ---- DISPLAY TEXT ---- */}
          <Typography variant='h2'>
            {this.state.invitation?.getEvaluator()?.getFullName()} has scheduled an {this.state.invitation?.getSegment()?.getName()} evaluation with you on
            {' ' + this.state.invitation?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME)}.
            Do you accept this invitation?
          </Typography>

          {/* ----- BUTTON CONFIRMATION ----- */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
            <Button disabled={this.state.isLoading} isLoading={this.state.isAcceptLoading} style={{ width: '49%', marginRight: '10px' }} onClick={() => this.acceptEvaluationInvitation()}>
              Yes, I accept
            </Button>
            <Button outlined disabled={this.state.isLoading} isLoading={this.state.isRejectLoading} style={{ width: '49%', marginRight: '10px' }} onClick={() => this.rejectEvaluationInvitation()}>
              No, I do not
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

EvaluationInvitation.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(EvaluationInvitation);
