import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// API
import { listGroupAdminAssociations, getGroup } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';

import { Typography } from '@mui/material';
import { Table } from '@lexcelon/react-util';

const COLUMNS = [
  {
    title: 'Name',
    render: rowData => rowData?.getPerson()?.getFullName(),
    customFilterAndSearch: (term, rowData) => rowData?.getPerson()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getPerson()?.getFullName()?.localeCompare(b.getPerson()?.getFullName()),
  },
  {
    title: 'Email',
    render: rowData => rowData?.getPerson()?.getEmail(),
    customFilterAndSearch: (term, rowData) => rowData?.getPerson()?.getEmail()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getPerson()?.getEmail()?.localeCompare(b.getPerson()?.getEmail()),
  },
  {
    title: 'Phone Number',
    render: rowData => rowData?.getPerson()?.getPhoneNumber(),
    customFilterAndSearch: (term, rowData) => rowData?.getPerson()?.getPhoneNumber()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getPerson()?.getPhoneNumber()?.localeCompare(b.getPerson()?.getPhoneNumber()),
  },
  {
    title: 'Is Active in Group',
    render: rowData => rowData?.getIsActive() ? 'Active' : 'Inactive',
    customSort: (a, b) => a.getIsActive() - b.getIsActive(),
  },
  {
    title: 'Is Account Suspended',
    render: rowData => rowData?.getPerson()?.getSuspended() ? 'Suspended' : 'Active',
    customSort: (a, b) => a.getPerson()?.getSuspended() - b.getPerson()?.getSuspended(),
  },
  {
    title: 'Address',
    render: rowData => rowData?.getPerson()?.getEvaluator()?.getWorkAddress()?.getFullAddress(),
    customFilterAndSearch: (term, rowData) => (rowData?.getPerson()?.getEvaluator()?.getWorkAddress()?.getFullAddress()?.toLowerCase()?.includes(term.toLowerCase())),
  },
];

class GroupAdmins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admins: [],
      group: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch(error => {
      setError(error ?? 'Error: Could not load group');
    });

    this.setState({ isLoading: true });
    listGroupAdminAssociations({ groupId }).then(({ results }) => {
      this.setState({
        admins: results,
        isLoading: false
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve admins');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>{this.state.group?.getName()} Admins</Typography>
        <Table
          title=''
          data={this.state.admins}
          columns={COLUMNS}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

GroupAdmins.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GroupAdmins);
