import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Paper, Typography } from '@mui/material';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// Components
import { Button, LineItem } from '@lexcelon/react-util';
import { ROUSING_RED } from '@ergonauts/ergo-algo-react/core/constants';

class ViewEvaluationWidget extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.props.evaluation?.getEvaluee()?.getFullName()}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center' }}>Evaluation</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: ROUSING_RED, fontStyle: 'italic' }}>{this.props.evaluation?.getStatus()}</Typography>

        <Typography variant='h2' style={{ marginTop: '1em' }}>Overview</Typography>

        <LineItem
          value={this.props.evaluation?.getEvaluator()?.getFullName()}
          description='Evaluator'
        />

        <LineItem
          value={this.props.evaluation?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME)}
          description='Date/Time'
        />

        <LineItem
          value={this.props.evaluation?.getGroup()?.getName()}
          description='Group'
        />

        <LineItem
          value={this.props.evaluation?.getSegment()?.getName()}
          description='Segment'
        />

        <LineItem
          value={this.props.evaluation?.getEvaluee()?.getFullName()}
          description='Evaluee'
        />

        <LineItem
          value={this.props.evaluation?.getEvaluee()?.getEmail()}
          description='Evaluee Email'
        />

        <LineItem
          value={this.props.evaluation?.getAddress()?.getFullAddress()}
          description='Evaluation Address'
        />

        <div style={{ justifyContent: 'center' }}>
          <Paper style={{ justifyContent: 'center' }}>
            <Document file='https://ergoalgostoragedev.blob.core.windows.net/image-storage/Evaluation_Report.pdf'>
              <Page pageNumber={1} />
            </Document>
          </Paper>
        </div>

        <div style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Routes to the pdf link where they can download. To force a download, might be a server problem
            https://stackoverflow.com/questions/2598658/how-to-force-a-pdf-download-automatically */}
          <Button
            href='https://ergoalgostoragedev.blob.core.windows.net/image-storage/Evaluation_Report.pdf'
            target='_blank'
            rel='noreferrer'>Download Results</Button>
        </div>
      </Container>
    );
  }
}

ViewEvaluationWidget.propTypes = {
  evaluation: PropTypes.object.isRequired
};

export default ViewEvaluationWidget;
