import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../alerts';

// APIs
import { getGroup } from '../../../api';

// Components
import { LineItem } from '@lexcelon/react-util';
import { ROUSING_RED, FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      redirect: false,
    };

    this.loadGroup = this.loadGroup.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  loadGroup() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch((error) => {
      setError(error ?? 'Error: Unable to load that requested group.');

      this.setState({
        redirect: true
      });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/group'} />;
    }

    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.group?.getName()}</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: ROUSING_RED, fontStyle: 'italic' }}>{this.state.group?.getIsActive() ? 'Active' : 'Inactive'}</Typography>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5em', marginBottom: '0.3em' }}>
          <img src={this.state.group?.getLogoUrl()} style={{ width: '150px', height: 'auto' }} />
        </div>

        <LineItem
          value={this.state.group?.getContractExpiration()?.toLocaleString(FULL_DATE_TIME)}
          description='Contract Expiration Date'
        />

        <LineItem
          value={this.state.group?.getContractMaxEvaluators()}
          description='Max Number of Evaluators (in Contract)'
        />

        <LineItem
          value={this.state.group?.getContractMaxEvaluations()}
          description='Max Number of Evaluations (in Contract)'
        />

        <LineItem
          value={this.state.group?.getAddress()?.getFullAddress()}
          description='group Location'
        />

        <LineItem
          value={this.state.group?.getContactName()}
          description='Contact Name'
        />

        <LineItem
          value={this.state.group?.getContactPhoneNumber()}
          description='Contact Phone Number'
        />

        <LineItem
          value={this.state.group?.getContactEmail()}
          description='Contact Email'
        />

        <LineItem
          value={this.state.group?.getEvalueeCanViewReport() ? 'Yes' : 'No'}
          description='Can Evaluee View Report'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getAdminCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/admins'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Admins'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getEvaluatorCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/evaluators'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Evaluators'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getEvaluationCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/evaluations'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Evaluations'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getInvitationCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/invitations'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Outstanding Invitations'
        />
      </Container>
    );
  }
}

Group.propTypes = {
  match: PropTypes.any
};

export default withRouter(Group);
