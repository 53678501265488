import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// Alerts
import { clearErrors } from '../../alerts';

// Components
import { TrainingLibraryWidget, UpcomingErgonarsWidget } from '../../components';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  ergonaursContainer: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: '5px 2.5% 5px 2.5%'
    }
  },
  trainingsContainer: {
    flex: 2,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: '5px 2.5% 5px 2.5%'
    }
  }
});

class Training extends Component {
  componentWillUnmount() {
    clearErrors();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.ergonaursContainer}>
          <UpcomingErgonarsWidget height={window.innerHeight - 275} />
        </div>

        <div className={classes.trainingsContainer}>
          <TrainingLibraryWidget height={window.innerHeight - 275} />
        </div>
      </div>
    );
  }
}

Training.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Training);
