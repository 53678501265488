import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Api
import { setupFullyAuthenticatePerson } from '../../../../redux/actions/authActions';
import { sendTwoFactorVerification } from '../../../../api';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// Components
import { Container, Typography, FormControlLabel, RadioGroup, Radio, TextField, Tooltip } from '@mui/material';
import { Button } from '@lexcelon/react-util';

class TwoFactorSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationType: '',
      selectedOption: 'SMS',
      apiCall: null,
      sentMessage: '',
      authCode: '',
      isLoading: false,
      disableResend: true
    };
  }

  componentDidMount() {
    this.resendTimer = setTimeout(() => this.setState({ disableResend: false }), 120000);
  }

  componentWillUnmount() {
    clearTimeout(this.resendTimer);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendVerification = (e, isResend = false) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    let sentMessage = null;
    let resendMessage = null;
    let errorMessage = null;
    switch (this.state.selectedOption) {
      case 'SMS':
        sentMessage = `Text message containing verification code sent to ${this.props.phoneNumber}.`;
        resendMessage = `Successfully resent verification code to ${this.props.phoneNumber}.`;
        errorMessage = 'Error: Could not send text message.';
        break;
      case 'CALL':
        sentMessage = `Call containing verification code made to ${this.props.phoneNumber}.`;
        resendMessage = `Successfully resent verification code to ${this.props.phoneNumber}.`;
        errorMessage = 'Error: Could not make call.';
        break;
      // case 'EMAIL':
      //   sentMessage =  `Email containing verification code sent to ${this.props.email}.`;
      //   resendMessage = `Successfully resent verification code to ${this.props.email}.`;
      //   errorMessage = 'Error: Could not send email.';
      //   break;
    }


    sendTwoFactorVerification(this.state.selectedOption).then(() => {
      this.setState({ isLoading: false, verificationType: this.state.selectedOption, sentMessage, disableResend: true });
      if (isResend) setSuccess(resendMessage);
      this.resendTimer = setTimeout(() => this.setState({ disableResend: false }), 120000);
    }).catch((error) => {
      setError(error ?? errorMessage);
      this.setState({ isLoading: false });
      return;
    });
  }

  onVerificationSubmit = (e) => {
    e.preventDefault();

    let strippedAuthCode = this.state.authCode.replace(/\s/g, '');
    this.props.setupFullyAuthenticatePerson(strippedAuthCode);
  }

  useDifferentVerificationMethod = () => {
    this.setState({ selectedOption: 'SMS', verificationType: '' });
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Two-Factor Authentication</Typography>
        {this.state.verificationType == '' &&
          <>
            <Typography style={{ fontSize: '24px', textAlign: 'center' }}>How would you prefer to receive your two factor authentication code?</Typography>
            <form onSubmit={this.sendVerification} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <RadioGroup
                style={{ margin: '2em' }}
                defaultValue={'SMS'}
                name='selectedOption'
                onChange={this.onChange}>
                <FormControlLabel disabled={this.state.isLoading} value='SMS' control={<Radio />} label={'Send a text message to ' + this.props.phoneNumber} />
                <FormControlLabel disabled={this.state.isLoading} value='CALL' control={<Radio />} label={'Call ' + this.props.phoneNumber} />
                {/* <FormControlLabel disabled={this.state.isLoading} value='EMAIL' control={<Radio />} label={'Send an email to ' + this.props.email} /> */}
              </RadioGroup>
              <Button type='submit' isLoading={this.state.isLoading} style={{ width: 300 }}>
                Send
              </Button>
            </form>
          </>
        }
        {this.state.verificationType &&
          <>
            <Typography style={{ fontSize: '24px', textAlign: 'center' }}>{this.state.sentMessage} Please enter your verification code below.</Typography>
            <form onSubmit={this.onVerificationSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

              <TextField
                required
                name='authCode'
                label='Verification Code'
                style={{ margin: '2em', width: 300 }}
                onChange={this.onChange}
                variant='filled'
                disabled={this.props.isLoadingVerification || this.state.isLoading}
              />

              <Button type='submit' style={{ width: 300, marginTop: '0' }} isLoading={this.props.isLoadingVerification} disabled={this.state.isLoading}>Submit</Button>
              <Tooltip title={this.state.disableResend ? 'Must wait 2 minutes before resending.' : ''}>
                <span>
                  <Button secondary onClick={(e) => this.sendVerification(e, true)} isLoading={this.state.isLoading} disabled={this.props.isLoadingVerification || this.state.disableResend } style={{ width: 275, marginTop: '2em' }}>Resend verification code</Button>
                </span>
              </Tooltip>
              <Button secondary onClick={this.useDifferentVerificationMethod} style={{ width: 275, marginTop: '.5em' }} disabled={this.state.isLoading || this.props.isLoadingVerification}>Use different verification method</Button>
            </form>
          </>
        }

      </Container >
    );
  }
}

TwoFactorSetup.propTypes = {
  setupFullyAuthenticatePerson: PropTypes.func.isRequired,
  isLoadingVerification: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isLoadingVerification: state.auth.isLoading,
  email: state.auth.email,
  phoneNumber: state.auth.phoneNumber
});

const mapDispatchToProps = (dispatch) => ({
  setupFullyAuthenticatePerson: (token) => setupFullyAuthenticatePerson(dispatch, token)
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorSetup);
