import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

export default function AuthRedirect(location, authProps) {
  const {
    isLoading,
    isSoftAuthenticated,
    isFullyAuthenticated,
    twoFactorType,
    isAdmin,
    redirectUrl: authRedirectUrl
  } = authProps;

  if (!isLoading) {
    let pathname = location.pathname;
    let originalUrl = encodeURIComponent(pathname);
    let redirectUrl = authRedirectUrl ?? qs.parse(location.search, { ignoreQueryPrefix: true })?.redirect_uri;

    // Login
    if (pathname === '/auth/login' || pathname.includes('/auth/create-account') || pathname.includes('/auth/forgot-password')) {
      if (isSoftAuthenticated) {
        if (twoFactorType == null) return <Redirect to='/auth/two-factor/setup' />;
        else if (!isFullyAuthenticated) {
          if (redirectUrl != null) {
            return <Redirect to={'/auth/two-factor?redirect_uri=' + redirectUrl} />;
          }
          else {
            return <Redirect to='/auth/two-factor' />;
          }
        }
        else return <Redirect to='/' />;
      }
    }

    // Two Factor Setup or Authentication
    else if (pathname.includes('/auth/two-factor')) {
      if (!isSoftAuthenticated) return <Redirect to='/auth/login' />;
      else if (isFullyAuthenticated) {
        if (redirectUrl != null) {
          return <Redirect to={redirectUrl} />;
        }
        else {
          return <Redirect to='/' />;
        }
      }
      else if (pathname.includes('/auth/two-factor/setup') && twoFactorType != null) <Redirect to='/auth/two-factor' />;
      else if (pathname.includes('/auth/two-factor') && twoFactorType == null) <Redirect to='/auth/two-factor/setup' />;
    }

    // Admin Redirect
    else if (pathname.includes('/auth/login/redirect')) {
      if (!isFullyAuthenticated) return <Redirect to='/auth/login' />;
      else if (isAdmin) return <Redirect to='/admin' />;
      return null;
    }

    // Account Pages
    else if (pathname.startsWith('/account')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    // Evaluation Pages
    else if (pathname.startsWith('/evaluation')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    // Evaluator Pages
    else if (pathname.startsWith('/evaluator')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    // Evaluee Pages
    else if (pathname.startsWith('/evaluee')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    else if (pathname.startsWith('/groups')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    // Evaluee Pages
    else if (pathname.includes('/evaluee')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    else if (pathname.includes('/groups')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    // Training Pages
    else if (pathname.startsWith('/training')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }

    // Group Invitation
    else if (pathname.includes('/invitations/group')) {
      if (!isFullyAuthenticated) return <Redirect to={'/auth/login?redirect_uri=' + originalUrl} />;
      return null;
    }
  }
  return null;
}
