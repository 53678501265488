import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Typography, TextField, MenuItem, Checkbox } from '@mui/material';
import { isEmail, isValidPhoneNumber } from '@lexcelon/js-util/lib/validator';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Components
import { Button } from '@lexcelon/react-util';
import AddressInput from '../../../components/AddressInput';

// Server
import { requestEvaluation } from '../../../api';

// Alerts
import { setSuccess, setError } from '../../../alerts';


class RequestEvaluation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      phoneNumber: '',
      phoneType: '',
      contactAgreement: false,
      redirectToLanding: false,
      isLoading: false
    };
  }

  componentDidMount() {
    if (window && window.google) {
      var address1Field = document.querySelector('#line1');
      this.setState({
        autocomplete: new window.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components'],
          types: ['address'],
        })
      }, () => {
        this.state.autocomplete.addListener('place_changed', this.fillInAddress);
      });
    }

    this.fillInAddress = this.fillInAddress.bind(this);
  }

  fillInAddress() {
    if (this.state.autocomplete) {
      // Get the place details from the autocomplete object.
      const place = this.state.autocomplete.getPlace();
      let address1 = '';
      var city = '';
      var state = '';
      var zip = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code':
            zip = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          default:
        }
      }

      this.setState({ address: { line1: address1, city: city, state: state, zip: zip } });

      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      var address2Field = document.querySelector('#line2');
      address2Field.focus();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  clearForm = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      phoneNumber: '',
      phoneType: '',
      contactAgreement: false
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      address,
      phoneType
    } = this.state;

    if (!isValidPhoneNumber(phoneNumber)) {
      setError('Error: Check to see if you have entered a valid phone number.');
      return;
    }

    if (!isEmail(email)) {
      setError('Error: Check to see if you have entered a valid email.');
      return;
    }

    this.setState({ isLoading: true });
    requestEvaluation({ firstName, lastName, email, address, phoneNumber, phoneType }).then(() => {
      this.setState({ isLoading: false, redirectToLanding: true });
      setSuccess('Successfully submitted evaulation request.');
      this.clearForm();
    }).catch((error) => {
      this.setState({ isLoading: false });
      setError(error);
    });

  }

  render() {
    return this.state.redirectToLanding ? <Redirect to='/landing' /> : (
      <Container>
        <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Request an Evaluation</Typography>
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Name</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* First Name Input */}
            <TextField
              required
              error={this.state.formError && this.state.firstName === ''}
              name="firstName"
              label="First Name"
              type='text'
              style={{ width: '49%' }}
              value={this.state.firstName}
              onChange={this.onChange}
              variant='filled'
            />

            {/* Last Name Input */}
            <TextField
              required
              error={this.state.formError && this.state.lastName === ''}
              name="lastName"
              label="Last Name"
              type='text'
              style={{ width: '49%' }}
              value={this.state.lastName}
              onChange={this.onChange}
              variant='filled'
            />
          </div>

          {/* ----- ADDRESS ----- */}
          <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '20px' }}>Address</Typography>

          <AddressInput
            isLoading={this.state.isLoading}
            onChange={(address) => this.setState({ address })}
            requiredFields={['line1', 'city', 'state', 'zip']}
          />

          {/* ----- CONTACT ----- */}
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Contact</Typography>

          {/* Email Input */}
          <TextField
            required
            error={this.state.formError && this.state.email === ''}
            name="email"
            label="Email"
            type='email'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
          />

          {/* Phone Number */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ReactPhoneInput
              country='us'
              onlyCountries={['us']}
              value={this.state.phoneNumber}
              onChange={(phoneNumber) => this.setState({ phoneNumber })}
              component={TextField}
              inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading }}
              disableCountryCode={true}
              disableDropdown={true}
              placeholder='(702) 123-4567'
              containerStyle={{ width: '100%', marginBottom: '10px' }}
            />

            <TextField
              required
              select
              error={this.state.formError && this.state.phoneType === ''}
              name="phoneType"
              label="Phone Type"
              style={{ width: '24%' }}
              value={this.state.phoneType}
              onChange={this.onChange}
              variant='filled'>
              <MenuItem value={'Cell'}>
                Cell
              </MenuItem>
              <MenuItem value={'Home'}>
                Home
              </MenuItem>
              <MenuItem value={'Work'}>
                Work
              </MenuItem>
            </TextField>
          </div>

          <div>
            <Checkbox
              required
              error={this.state.formError && this.state.contactAgreement === false}
              color="default"
              name="contactAgreement"
              checked={this.state.contactAgreement}
              onChange={this.onCheckboxChange} />
            <Typography variant='p' style={{ marginBottom: '10px', marginTop: '20px' }}>I agree to be contacted by Ergonauts or Ergonauts affiliates.</Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
            <Button type='submit' isLoading={this.state.isLoading} style={{ width: '50%' }}>
              Submit Evaluation Request
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

RequestEvaluation.propTypes = {
};

export default RequestEvaluation;
