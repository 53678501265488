import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import qs from 'qs';

// Alerts
import { setError, setSuccess } from '../../../alerts';

// API
import { getEvaluation, deleteEvaluation, resendEvalueeInvitation, regenerateReport, getPerson } from '../../../api';

// Components
import { EvaluationForm } from '../../../components';
import { PDFViewer } from '@ergonauts/ergo-algo-react/core/components';
import { Button, LineItem } from '@lexcelon/react-util';
import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

// Constants
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

class Evaluation extends Component {
  constructor(props) {
    super(props);

    this.groupId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.groupId;
    this.evaluatorId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.evaluatorId;

    this.state = {
      evaluation: null,
      person: null,
      isAdminOrEvaluator: false,
      inEditMode: false,
      redirect: false,
      isLoadingCancel: false,
      isLoadingResend: false,
      isLoadingRegenerate: false
    };
  }

  componentDidMount() {
    getPerson().then(person => {
      this.setState({ person: person });
      this.loadEvaluation();
    });
  }

  loadEvaluation() {
    const evaluationId = this.props.match?.params?.id;
    getEvaluation(evaluationId).then((evaluation) => {
      this.setState({ evaluation: evaluation });
      this.checkIfAdminOrEvaluator();
    }).catch((error) => {
      setError(error ?? 'Unable to load that requested Evaluation.');
      this.setState({ redirect: true });
    });
  }

  checkIfAdminOrEvaluator() {
    if (this.state.person?.getId() === this.state.evaluation?.getEvaluator()?.getId()) {
      this.setState({ isAdminOrEvaluator: true });
    }

    this.state.person?.getGroupAssociations().forEach(association => {
      if (association?.getGroupId() == this.state.evaluation?.getGroup()?.getId() && association?.getRole()?.getRole() == 'ADMINISTRATOR') {
        this.setState({ isAdminOrEvaluator: true });
      }
    });
  }

  onEditSuccess = () => {
    this.setState({
      inEditMode: false
    });

    this.loadEvaluation();
  }

  resendEvalueeInvitation() {
    this.setState({ isLoadingResend: true });
    resendEvalueeInvitation(this.state.evaluation?.id).then(() => {
      setSuccess('Successfully resent invitation');
      this.setState({ isLoadingResend: false });
    }).catch(error => {
      setError(error ?? 'Error: could not resend invitation');
      this.setState({ isLoadingResend: false });
    });
  }

  deleteEvaluation() {
    this.setState({ isLoadingCancel: true });
    deleteEvaluation(this.state.evaluation?.id).then(() => {
      setSuccess('Successfully cancelled evaluation');
      this.setState({ isLoadingCancel: false });
      this.loadEvaluation();
    }).catch(error => {
      setError(error ?? 'Could not cancel evaluation');
      this.setState({ isLoadingCancel: false });
    });
  }

  regenerateReport() {
    this.setState( { isLoadingRegenerate: true });
    regenerateReport(this.state.evaluation?.id).then(() => {
      setSuccess('Successfully requested report regeneration. Please wait a few minutes.');
      this.setState({ isLoadingRegenerate: false });
    }).catch(error => {
      setError(error ?? 'Error: could not regenerate report');
      this.setState({ isLoadingRegenerate: false });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/'} />;
    }

    else if (this.state.inEditMode) {
      return (
        <Container>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Edit Evaluation</Typography>
          <EvaluationForm
            groupId={this.groupId}
            onSuccess={this.onEditSuccess}
            evaluation={this.state.evaluation}
            evaluatorId={this.evaluatorId}
            onClose={() => this.setState({ inEditMode: false })}
          />
        </Container>
      );
    }

    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.evaluation?.getSegment()?.getName()} Evaluation</Typography>

        {/* Overview */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Overview</Typography>

          <LineItem
            value={
              (() => {
                switch (this.state.evaluation?.getStatus()) {
                  case 'SCHEDULED':
                    return 'Waiting for Evaluee to Accept Invitation (Emailed)';
                  case 'ACCEPTED':
                    return 'Ready for Evaluation';
                  default:
                    return enumToDisplayString(this.state.evaluation?.getStatus());
                }
              })()
            }
            description='Status'
          />
          <LineItem
            value={this.state.evaluation?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME)}
            description='Scheduled Date/Time'
          />
          { this.state.evaluation?.getStatus() == 'COMPLETED' &&
            <>
              <LineItem
                value={this.state.evaluation?.getStartTimestamp()?.toLocaleString(FULL_DATE_TIME)}
                description='Start Date/Time'
              />
              <LineItem
                value={this.state.evaluation?.getEndTimestamp()?.toLocaleString(FULL_DATE_TIME)}
                description='Completed Date/Time'
              />
            </>
          }
          <LineItem
            value={this.state.evaluation?.getAddress()?.getFullAddress()}
            description='Evaluation Address'
          />
          <LineItem
            value={this.state.evaluation?.getGroup()?.getName()}
            description='Group'
          />
        </>

        {/* Evaluee Info */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Evaluee</Typography>

          <LineItem
            value={this.state.evaluation?.getEvaluee()?.getFullName()}
            description='Evaluee'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluee()?.getEmail()}
            description='Evaluee Email'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluee()?.getPhoneNumber()}
            description='Evaluee Phone Number'
          />
          {this.state.person?.getId() != this.state.evaluation?.getEvaluee()?.getId() &&
          <LineItem
            value={this.state.evaluation?.getEvalueeCanViewReport() ? 'Yes' : 'No'}
            description='Evaluee Can View Report'
          />}
        </>

        {/* Evaluator Info */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Evaluator</Typography>

          <LineItem
            value={this.state.evaluation?.getEvaluator()?.getFullName()}
            description='Evaluator'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluator()?.getEmail()}
            description='Evaluator Email'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluator()?.getPhoneNumber()}
            description='Evaluator Phone Number'
          />
        </>

        {/* Report */}
        {this.state.evaluation?.getReportUrl() != null &&
        <PDFViewer style={{ marginTop: 30, marginBottom: 30 }} url={this.state.evaluation.getReportUrl()} />}

        {this.state.isAdminOrEvaluator &&
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(this.state.evaluation?.getStatus() == 'ACCEPTED' || this.state.evaluation?.getStatus() == 'SCHEDULED') &&
            <Button
              danger
              disabled={this.state.isLoadingCancel || this.state.isLoadingResend}
              isLoading={this.state.isLoadingCancel}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.deleteEvaluation();
              }}
            >
              Cancel Evaluation
            </Button>
          }

          {(this.state.evaluation?.getStatus() != 'COMPLETED' && this.state.evaluation?.getStatus() != 'CANCELLED' && this.state.evaluation?.getStatus() != 'REJECTED') &&
            <Button
              disabled={this.state.isLoadingCancel || this.state.isLoadingResend || this.state.isLoadingRegenerate}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.setState({
                  inEditMode: true
                });
              }}
              secondary
            >
              Edit Evaluation
            </Button>
          }

          {(this.state.evaluation?.getStatus() == 'SCHEDULED') &&
            <Button
              disabled={this.state.isLoadingCancel || this.state.isLoadingResend}
              isLoading={this.state.isLoadingResend}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.resendEvalueeInvitation();
              }}
              secondary
            >
              Resend Evaluee Invitation
            </Button>
          }

          {(this.state.evaluation?.getStatus() == 'COMPLETED') &&
            <Button
              secondary
              isLoading={this.state.isLoadingRegenerate}
              disabled={this.state.isLoadingCancel}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.regenerateReport();
              }}
            >
              Regenerate Report
            </Button>
          }
        </div>}

      </Container>
    );
  }
}

Evaluation.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object.isRequired
};

export default withRouter(Evaluation);
