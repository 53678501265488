import store from '../redux/store';
import { createConfirmation, removeConfirmation, startConfirmationLoading, stopConfirmationLoading } from '../redux/actions/confirmActions';

/**
 * @param {object} props 
 * @param {string} props.title Title text of the confirmation modal
 * @param {function} props.onConfirm What happens on successful confirmation
 * @param {function} [props.onClose] What happens on cancellation of the modal
 * @param {string} [props.body] Body text of the confirmation modal
 * @param {boolean} [props.danger] Whether or not the user is confirming a dangerous action
 */
export function confirm(props) {
  store?.dispatch(createConfirmation(props));
}

export function closeConfirm() {
  store?.dispatch(removeConfirmation());
}

export function startConfirmLoading() {
  store?.dispatch(startConfirmationLoading());
}

export function stopConfirmLoading() {
  store?.dispatch(stopConfirmationLoading());
}
