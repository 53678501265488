import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// API
import { listGroupInvitationAssociations, getGroup } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';

import { Typography } from '@mui/material';
import { Table } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

const COLUMNS = [
  {
    title: 'Email',
    render: rowData => rowData?.getPerson()?.getEmail(),
    customFilterAndSearch: (term, rowData) => rowData?.getPerson()?.getEmail()?.toLowerCase().includes(term?.toLowerCase()),
    customSort: (a, b) => a.getPerson()?.getEmail()?.localeCompare(b.getPerson()?.getEmail()),
  },
  {
    title: 'Role',
    render: rowData => rowData?.getRole()?.getRole(),
    customSort: (a, b) => a.getRole()?.getRole()?.localeCompare(b.getRole()?.getRole()),
  },
  {
    title: 'Sent on',
    render: rowData => rowData?.getCreatedAt()?.toLocaleString(FULL_DATE_TIME),
    customSort: (a, b) => a.getCreatedAt() - b.getCreatedAt(),
  },
  {
    title: 'Expires on',
    render: rowData => rowData?.getExpiresOn()?.toLocaleString(FULL_DATE_TIME),
    customSort: (a, b) => a.getCreatedAt() - b.getCreatedAt(),
  },
];

class GroupInvitations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invitations: [],
      group: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch(error => {
      setError(error ?? 'Error: Could not load group.');
    });
    this.loadInvitations(groupId);
  }

  loadInvitations(groupId) {
    this.setState({ isLoading: true });
    listGroupInvitationAssociations({ groupId }).then(({ results }) => {
      this.setState({ invitations: results, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve invitations');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>{this.state.group?.name} Invitations</Typography>
        <Table
          title={''}
          data={this.state.invitations}
          columns={COLUMNS}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

GroupInvitations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GroupInvitations);
