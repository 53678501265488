import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from '@stripe/react-stripe-js';

// Confirmation Alert
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

// APIs
import { getPaymentMethod, updatePaymentMethod, deletePaymentMethod } from '../../../api';

// Components
import { Button } from '@lexcelon/react-util';

// Alerts
import { clearErrors, setError, setSuccess } from '../../../alerts';

class BillingWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingPaymentMethod: false,
      isLoading: false,
      paymentMethod: null,
      inEditMode: false
    };
  }

  componentDidMount() {
    this.retrievePaymentMethod();
  }

  componentWillUnmount() {
    clearErrors();
  }

  retrievePaymentMethod = () => {
    this.setState({ isLoadingPaymentMethod: true });
    getPaymentMethod().then(paymentMethod => {
      let paymentMethodExists = paymentMethod != null && Object.keys(paymentMethod)?.length > 0;
      this.setState({
        paymentMethod: paymentMethodExists ? paymentMethod : null,
        isLoadingPaymentMethod: false
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to load existing payment method.');
      this.setState({ isLoadingPaymentMethod: false });
    });
  }

  savePaymentMethod = () => {
    const { stripe, elements } = this.props;
    if (stripe == null || elements == null) {
      setError('Error: Something went wrong with Stripe. Please try again later.');
      return;
    }

    // Check for Zip and Cardholder Name
    if (this.state.cardName == null || this.state.cardName === '') {
      setError('Error: Missing cardholder name.');
      return;
    }
    else if (this.state.zip == null || this.state.zip === '') {
      setError('Error: Missing zip code.');
      return;
    }

    // Disable card inputs
    this.setState({ isLoading: true });
    var cardNumberElement = elements.getElement('cardNumber');
    var cardExpiryElement = elements.getElement('cardExpiry');
    var cardCvcElement = elements.getElement('cardCvc');
    cardNumberElement?.update({ disabled: true });
    cardExpiryElement?.update({ disabled: true });
    cardCvcElement?.update({ disabled: true });

    // Submit the purchases
    stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: { // eslint-disable-line
        name: this.state.cardName
      }
    }).then(res => {
      if (res.error != null) throw res.error; // Stripe doesn't throw their own errors

      updatePaymentMethod(res?.paymentMethod?.id).then(() => {
        setSuccess('Successfully updated payment method.');
        this.setState({ isLoading: false, inEditMode: false, cardName: '', zip: '' });
        this.retrievePaymentMethod();
      }).catch(error => {
        setError(error ?? 'Error: Unable to update payment method.');
        this.setState({ isLoading: false, inEditMode: false, cardName: '', zip: '' });
      });
    }).catch(error => {
      var cardNumberElement = elements.getElement('cardNumber');
      var cardExpiryElement = elements.getElement('cardExpiry');
      var cardCvcElement = elements.getElement('cardCvc');
      setError(error ?? 'Error: Unable to create payment method with Stripe.');
      this.setState({ isLoading: false });
      cardNumberElement?.update({ disabled: false });
      cardExpiryElement?.update({ disabled: false });
      cardCvcElement?.update({ disabled: false });
    });
  }

  confirmDeletePaymentMethod = () => {
    confirm({
      title: 'Are you sure you want to delete your payment method?',
      body: 'This will end any subscriptions you currently have, which may mean you lose access to training and evaluations.',
      onConfirm: () => {
        startConfirmLoading();
        deletePaymentMethod().then(() => {
          setSuccess('Successfully deleted payment method.');
          stopConfirmLoading();
          this.retrievePaymentMethod();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to delete payment method.');
          stopConfirmLoading();
        });
      },
      danger: true
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Paper style={{ margin: 30, padding: 20 }} elevation={5}>

        {this.state.isLoadingPaymentMethod ? (
          <CircularProgress />
        ) : (
          this.state.inEditMode ? (
            <div>
              {/* New Payment Method */}
              <Typography variant='body1' style={{ marginBottom: 10 }}>New Payment Method</Typography>

              <TextField label='Name on Card' name='cardName' value={this.state.cardName} onChange={this.onChange} style={{ width: '100%', marginBottom: 10 }} disabled={this.state.isLoading || this.state.isLoadingInfo || this.state.isLoadingPaymentMethod} />
              <div style={{ border: '2px solid #bcbcbc', padding: '17px 10px 17px 10px', borderRadius: 5, marginBottom: 10 }}>
                <CardNumberElement options={inputStyleOptions} />
              </div>
              <div style={{ border: '2px solid #bcbcbc', padding: '17px 10px 17px 10px', borderRadius: 5, marginBottom: 10 }}>
                <CardExpiryElement options={inputStyleOptions} />
              </div>
              <div style={{ border: '2px solid #bcbcbc', padding: '17px 10px 17px 10px', borderRadius: 5, marginBottom: 10 }}>
                <CardCvcElement options={inputStyleOptions} />
              </div>
              <TextField label='Zip' name='zip' value={this.state.zip} onChange={this.onChange} style={{ width: '100%' }} disabled={this.state.isLoading || this.state.isLoadingInfo || this.state.isLoadingPaymentMethod} inputProps={{ maxLength: 5 }} />

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                <Button onClick={() => this.setState({ inEditMode: false, zip: '', cardName: '' })} style={{ width: '49%' }} secondary disabled={this.state.isLoading}>Cancel</Button>
                <Button onClick={this.savePaymentMethod} style={{ width: '49%' }} isLoading={this.state.isLoading}>Update</Button>
              </div>
            </div>
          ) : (
            this.state.paymentMethod == null ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography variant='body1'>No payment method saved</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button onClick={() => this.setState({ inEditMode: true })} secondary>Add Payment Method</Button>
                </Grid>
              </Grid>

            ) : (
              <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                {/* Existing Payment Method */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                  <Typography variant='body1'>{this.state.paymentMethod?.card?.brand} ending in {this.state.paymentMethod?.card?.last4}</Typography>
                  <Typography variant='overline'>Expires on {this.state.paymentMethod?.card?.exp_month}/{this.state.paymentMethod?.card?.exp_year}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Button style={{ width: 200 }} onClick={() => this.setState({ inEditMode: true })} disabled={this.state.isLoading || this.state.isLoadingPaymentMethod}>Update Payment Method</Button>
                  <Button style={{ width: 200, marginTop: 10 }} onClick={this.confirmDeletePaymentMethod} disabled={this.state.isLoading || this.state.isLoadingPaymentMethod} danger>Delete Payment Method</Button>
                </div>
              </div>
            )
          )
        )}
      </Paper>
    );
  }
}

const inputStyleOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Roboto',
      color: '#6b6b6b',
      height: '30px'
    }
  }
};

BillingWidget.propTypes = {
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired
};

const InjectedBillingWidget = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <BillingWidget elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedBillingWidget; //withStyles(styles, { withTheme: true })(PurchaseWidget);
