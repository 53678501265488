import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

class SegmentCertification extends Component {
  componentDidMount() {
    // const segmentId = this.props.match?.params?.id;
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Segment {this.props.match?.params?.id} Certification</Typography>
      </Container>
    );
  }
}

SegmentCertification.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(SegmentCertification);
