import {
  IS_LOADING,
  IS_NOT_LOADING,
  SOFT_AUTHENTICATED,
  FULLY_AUTHENTICATED,
  NOT_AUTHENTICATED
} from '../actions/authActions';

const initialState = {
  isLoading: true,
  isSoftAuthenticated: false,
  isFullyAuthenticated: false,
  twoFactorType: null,
  isAdmin: false,
  email: null,
  phoneNumber: null,
  redirectUrl: null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case IS_NOT_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case SOFT_AUTHENTICATED:
      return {
        isLoading: false,
        isSoftAuthenticated: true,
        isFullyAuthenticated: false,
        isAdmin: action.isAdmin,
        twoFactorType: action.twoFactorType,
        email: action.email,
        phoneNumber: action.phoneNumber,
        redirectUrl: null
      };
    case FULLY_AUTHENTICATED:
      return {
        ...state,
        isLoading: false,
        isSoftAuthenticated: true,
        isFullyAuthenticated: true,
        isAdmin: action.isAdmin,
        twoFactorType: action.twoFactorType,
        email: action.email,
        phoneNumber: action.phoneNumber,
        redirectUrl: action.redirectUrl
      };
    case NOT_AUTHENTICATED:
      return {
        isAdmin: false,
        isLoading: false,
        isSoftAuthenticated: false,
        isFullyAuthenticated: false,
        twoFactorType: null,
        email: null,
        phoneNumber: null,
        redirectUrl: null
      };
    default:
      return state;
  }
};

export default auth;
