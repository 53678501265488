import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import { Redirect } from 'react-router';

// Components
import { EvaluatorProfileForm } from '../../../components';

// API
import { getEvaluator } from '../../../api';

class CreateProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEvaluator: false,
      profileCreated: false
    };
  }

  componentDidMount() {
    // If the evaluator profile has already been created, redirect person to account settings page to edit
    getEvaluator().then((evaluator) => {
      this.setState({
        isEvaluator: evaluator.workAddressId != null
      });
    }).catch(() => {});
  }

  onSuccess = () => {
    this.setState({ profileCreated: true });
  };

  render() {
    if (this.state.isEvaluator) {
      return <Redirect to='/account/account-settings' />;
    }

    else if (this.state.profileCreated) {
      return <Redirect to='/' />;
    }

    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Create Evaluator Profile</Typography>

        <EvaluatorProfileForm onSuccess={this.onSuccess} />
      </Container>
    );
  }
}

CreateProfile.propTypes = {
};

export default CreateProfile;
