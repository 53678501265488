import Cookies from 'js-cookie';
import { setError, setSuccess, clearErrors, updateAlert, removeAlert } from './alertActions';

// Server Code
import { checkTwoFactorVerification, getPerson, logoutPerson as logoutPersonFromServer, loginPerson as loginPersonFromServer, resendWelcomeEmail } from '../../api';

// Constants
export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const SOFT_AUTHENTICATED = 'SOFT_AUTHENTICATED';
export const FULLY_AUTHENTICATED = 'FULLY_AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';

// eslint-disable-next-line no-undef
const SOFT_COOKIE_NAME = process.env.REACT_APP_SOFT_COOKIE_NAME;
// eslint-disable-next-line no-undef
const FULL_COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;

export const startLoading = () => ({
  type: IS_LOADING
});

export const stopLoading = () => ({
  type: IS_NOT_LOADING
});

export const softAuthenticated = (isAdmin = false, twoFactorType = false, email = null, phoneNumber = null) => ({
  type: SOFT_AUTHENTICATED,
  isAdmin,
  twoFactorType,
  email,
  phoneNumber
});

export const fullyAuthenticated = (isAdmin = false, redirectUrl = null) => ({
  type: FULLY_AUTHENTICATED,
  isAdmin,
  redirectUrl
});

export const notAuthenticated = () => ({
  type: NOT_AUTHENTICATED
});

/* ----- AUTHENTICATE ----- */

export const softAuthenticatePerson = (dispatch, email = null, password = null) => {
  dispatch(startLoading());

  loginPersonFromServer({ email, password }).then(person => {
    dispatch(clearErrors());
    dispatch(softAuthenticated(person.getIsAdmin(), person.getTwoFactorType(), person.getEmail(), person.getPhoneNumber()));
  }).catch(error => {
    setError(dispatch, error, { onCreation: (alert) => {
      if (error.includes('You must confirm your email')) {
        dispatch(updateAlert(alert.id, {
          action: {
            label: 'Resend',
            onPress: () => {
              dispatch(updateAlert(alert.id, { action: { isLoading: true } }));
              resendWelcomeEmail(email).then(() => {
                setSuccess(dispatch, 'If we haven\'t sent your verification email in the past 10 minutes, we have successfully resent it. Please check your email inbox and spam folders for the welcome and verification email. Click the link to verify your account.');
                dispatch(removeAlert(alert.id));
              }).catch(error => {
                dispatch(removeAlert(alert.id));
                setError(dispatch, error ?? 'Error: Unable to resend verification email. Please try again later.');
              });
            }
          }
        }));
      }
    } });
    dispatch(notAuthenticated());
  });
};

export const setupFullyAuthenticatePerson = (dispatch, code) => {
  dispatch(startLoading());

  checkTwoFactorVerification(code).then(person => {
    let groupAssociationToken = person.getGroupAssociations()?.length > 0 ? person.getGroupAssociations()[0].getToken() : null;
    let redirectUrl = groupAssociationToken != null ? `/invitations/group/${groupAssociationToken}` : null;

    dispatch(clearErrors());
    setSuccess(dispatch, 'Successfully set up 2FA.');
    dispatch(fullyAuthenticated(person.getIsAdmin(), redirectUrl));
  }).catch(error => {
    setError(dispatch, error);
    dispatch(stopLoading());
  });
};

export const fullyAuthenticatePerson = (dispatch, code = null) => {
  dispatch(startLoading());

  checkTwoFactorVerification(code).then(person => {
    let groupAssociationToken = person.getGroupAssociations()?.length > 0 ? person.getGroupAssociations()[0].getToken() : null;
    let redirectUrl = groupAssociationToken != null ? `/invitations/group/${groupAssociationToken}` : null;

    dispatch(clearErrors());
    dispatch(fullyAuthenticated(person.getIsAdmin(), redirectUrl));
  }).catch(error => {
    setError(dispatch, error);
    dispatch(stopLoading());
  });
};

export const checkAuthentication = (dispatch, isSoftAuthenticated, isFullyAuthenticated) => {
  dispatch(startLoading());

  // If we falsely have the person logged in, log them out and clear all cookies.
  if ((Cookies.get(SOFT_COOKIE_NAME) == null && isSoftAuthenticated) || (Cookies.get(FULL_COOKIE_NAME) == null && isFullyAuthenticated)) {
    logoutPerson(dispatch);
  }

  else if (Cookies.get(SOFT_COOKIE_NAME) != null && !isSoftAuthenticated) {
    // If both cookies are there, fully authenticate
    if (Cookies.get(FULL_COOKIE_NAME) != null && !isFullyAuthenticated) {
      getPerson().then(person => {
        dispatch(fullyAuthenticated(person?.getIsAdmin()));
      }).catch(error => {
        console.log(error);
        dispatch(fullyAuthenticated(false));
      });
    }

    // If only the soft cookie is there, fully logout the person to prevent a weird limbo state
    else if (Cookies.get(FULL_COOKIE_NAME) == null) {
      logoutPerson(dispatch);
    }
  }

  else {
    dispatch(stopLoading());
  }
};

export const logoutPerson = (dispatch) => {
  // Revoke tokens and unauthenticate in redux state
  if (Cookies.get(SOFT_COOKIE_NAME) != null && Cookies.get(FULL_COOKIE_NAME) != null) {
    logoutPersonFromServer().then(() => {
      setSuccess(dispatch, 'Successfully logged out.');
      clearCookies(dispatch);
    }).catch(() => {
      clearCookies(dispatch);
    });
  }
  else clearCookies(dispatch);
};

export const clearCookies = (dispatch = null) => {
  // eslint-disable-next-line no-undef
  Cookies.remove(SOFT_COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  // eslint-disable-next-line no-undef
  Cookies.remove(FULL_COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  if (dispatch != null) dispatch(notAuthenticated());
};
