// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Alerts
import { setError } from '../../../alerts';

// API
import { listEvalueePreviousEvaluations, listEvalueeUpcomingEvaluations } from '../../../api';

// Components
import EvaluationWidget from './EvaluationWidget';

// Styling
const styles = theme => ({
  pageTitle: {
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '1.5em'
  },
  sectionTitle: {
    textAlign: 'left',
    marginBottom: '1em',
    marginTop: '1.5em'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  hoverText: {
    textAlign: 'center',
    marginTop: '1em'
  }
});

class Evaluations extends Component {
  constructor(props) {
    super(props);

    this.state =  {
      upcomingEvaluations: [],
      previousEvaluations: [],
    };
  }

  componentDidMount() {
    // upcoming vs previous are distinguished by 'Complete' status
    listEvalueeUpcomingEvaluations().then(({ results }) => {
      this.setState({ upcomingEvaluations: results });
    }).catch(error => {
      setError(error ?? 'Unable to retrieve upcoming evaluations.');
    });

    listEvalueePreviousEvaluations().then(({ results }) => {
      this.setState({ previousEvaluations: results });
    }).catch(error => {
      setError(error ?? 'Unable to retrieve previous evaluations.');
    });
  }

  getFormattedAddress(address) {
    if (address == null)
      return;
    return (address.getLine2() != null) ? (
      address.getLine1() + ' ' +
      address.getLine2() + ' ' +
      address.getCityStateZip()
    ) : (
      address.getLine1() + ' ' +
      address.getCityStateZip()
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Container style={{ paddingBottom: '2em' }}>
        {/* Page Header */}
        <Typography variant='h1' className={classes.pageTitle}>Your Evaluations</Typography>

        {/* Evaluation Card List */}
        <Typography variant='h2' className={classes.sectionTitle}>Upcoming Evaluations</Typography>
        {this.state.upcomingEvaluations.length !== 0 ? (
          this.state.upcomingEvaluations?.map((evaluation, index) => {
            return <EvaluationWidget key={index} evaluation={evaluation} />;
          })
        ) : (
          <Typography variant='h6'>No upcoming evaluations to show.</Typography>
        )}

        <Typography variant='h2' className={classes.sectionTitle}>Previous Evaluations</Typography>
        {this.state.previousEvaluations.length !== 0 ? (
          this.state.previousEvaluations.map((evaluation, index) => {
            return <EvaluationWidget key={index} evaluation={evaluation} />;
          })
        ) : (
          <Typography variant='h6'>No previous evaluations to show.</Typography>
        )}
      </Container>
    );
  }
}

Evaluations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Evaluations);
