import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Alerts
import { setError } from '../../../alerts';

// API
import { listSegments, listEvaluatorCertifications } from '../../../api';

// Components
import SegmentWidget from './SegmentWidget';
import CertificationWidget from './CertificationWidget';

// Styles
const styles = theme => ({
  title: {
    marginBottom: '0.5em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
});

class Segments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certifications: [],
      segments: [],
      isLoadingSegments: true
    };
  }

  componentDidMount() {
    // Retrieve list of certifications
    listEvaluatorCertifications().then(({ results }) => {
      this.setState({ certifications: results });
    }).catch(error => {
      if (error != 'Error: You do not have an evaluator account.') {
        setError(error ?? 'Error: Unable to retrieve list of certifications.');
      }
    });

    // Retrieve list of segments
    listSegments().then(({ results }) => {
      this.setState({ segments: results, isLoadingSegments: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve list of segments.');
      this.setState({ isLoadingSegments: false });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em 0em 1em 0em' }}>ErgoAlgo™ Segment Certifications</Typography>

        {/* Your Active Certifications */}
        {this.state.certifications?.filter(certification => certification?.getIsComplete())?.length > 0 &&
        <div style={{ marginBottom: '1em' }}>
          <Typography variant='h2' className={classes.title}>Your Active Certifications</Typography>

          <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            {this.state.certifications?.filter(certification => certification?.getIsComplete())?.map((certification, index) => (
              <CertificationWidget key={index} evaluatorCertification={certification} />
            ))}
          </div>
        </div>}

        {/* Your In Progress Certifications */}
        {this.state.certifications?.filter(certification => !certification?.getIsComplete())?.length > 0 &&
        <div style={{ marginBottom: '1em' }}>
          <Typography variant='h2' className={classes.title}>Your In Progress Certifications</Typography>

          <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            {this.state.certifications?.filter(certification => !certification?.getIsComplete())?.map((certification, index) => (
              <CertificationWidget key={index} evaluatorCertification={certification} />
            ))}
          </div>
        </div>}

        {/* All Available Segment Certifications */}
        <div style={{ marginBottom: '1em' }}>
          <Typography variant='h2' className={classes.title}>All Available Segment Certifications</Typography>

          <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            {this.state.segments?.map((segment, index) => (
              <SegmentWidget key={index} ergoAlgoSegment={segment} />
            ))}
            {this.state.isLoadingSegments &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>}
          </div>
        </div>
      </Container>
    );
  }
}

Segments.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Segments);
