import React, { Component } from 'react';
import { Container, Typography, TextField, Link } from '@mui/material';

// Components
import { Button } from '@lexcelon/react-util';

// Server
import { requestForgotPassword } from '../../../api';

// Alerts
import { setError, setSuccess } from '../../../alerts';
import { Redirect } from 'react-router-dom';

class RequestPasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      requestSubmitted: false
    };

    this.exitChangePasswordMode = this.exitChangePasswordMode.bind(this);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.email === '') {
      setError('Error: Please enter an email.');
    }

    this.setState({ isLoading: true });
    requestForgotPassword(this.state.email).then(() => {
      this.setState({ requestSubmitted: true });
      setSuccess('Email submitted!');
    }).catch((error) => {
      setError(error ?? 'Error, please try again.');
      this.setState({ isLoading: false });
    });

  }

  exitChangePasswordMode() {
    this.setState({
      exitChangePasswordMode: true
    });
  }

  render() {
    if (this.state.exitChangePasswordMode) {
      return <Redirect to='/auth/login' />;
    }

    return (
      <Container>
        {!this.state.requestSubmitted &&
          <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
            {/* ----- FORGOT PASSWORD ----- */}
            <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Forgot Password</Typography>

            {/* Email Input */}
            <TextField
              required
              error={this.state.formError && this.state.email === ''}
              name="email"
              label="Email"
              type='email'
              style={{ width: '100%' }}
              value={this.state.email}
              onChange={this.onChange}
              variant='filled'
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
              <Button secondary onClick={this.exitChangePasswordMode} disabled={this.state.isLoading} style={{ width: '49%' }}>
                Back to Login
              </Button>

              <Button type='submit' isLoading={this.state.isLoading} style={{ width: '49%' }}>
                Submit
              </Button>
            </div>
          </form>
        }

        {this.state.requestSubmitted &&
          <div>
            <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>
              If an account for that email exists, you should receive an email with instructions on changing your password in the next few minutes.
            </Typography>
            <div style={{ width: '30%', margin: 'auto' }}>
              <Link href='/auth/login'>
                <Button onClick={this.exitChangePasswordMode} style={{ width: '100%' }}>
                  Back to Login
                </Button>
              </Link>
            </div>
          </div>
        }
      </Container>
    );
  }
}

RequestPasswordReset.propTypes = {
};

export default RequestPasswordReset;
