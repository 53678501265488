import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Grid, RadioGroup, FormControlLabel, Radio, Paper, Typography, Divider } from '@mui/material';

// Server
import { getPerson, cancelSubscription, resumeSubscription, listSubscriptions, changeSubscription, getSegment } from '../../../api';

// Redux
import { connect } from 'react-redux';
import { clearErrors, setError, setSuccess } from '../../../alerts';
import { confirm, closeConfirm, startConfirmLoading } from '../../../alerts/confirm';

// Components
import { Button } from '@lexcelon/react-util';
import BillingWidget from './BillingWidget';

class MyBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: null,
      subscriptions: [],
      inChangeSubscriptionForSubscriptionId: null,
      selectedSubscriptionTierId: null,
      isLoadingSegment: false
    };
  }

  componentDidMount() {
    if (this.props.isFullyAuthenticated) {
      this.retrievePerson();
      this.retrieveSubscriptions();
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.isFullyAuthenticated != this.props.isFullyAuthenticated && this.props.isFullyAuthenticated) {
      this.retrievePerson();
      this.retrieveSubscriptions();
    }
  }

  componentWillUnmount() {
    clearErrors();
  }

  retrievePerson = () => {
    this.setState({ isLoading: true });
    getPerson().then((person) => {
      this.setState({ person: person, isLoading: false });
    }).catch((error) => {
      this.setState({ isLoading: false });
      setError(error ?? 'Error: Could not retrieve person.');
    });
  }

  retrieveSubscriptions = () => {
    listSubscriptions().then(({ results }) => {
      this.setState({ subscriptions: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to load subscriptions. Please try reloading your browser.');
    });
  }

  startChangeSubscription = (subscriptionId) => {
    this.setState({ inChangeSubscriptionForSubscriptionId: subscriptionId });
    let subscription = this.state.subscriptions?.find(subscription => subscription.getId() === subscriptionId);
    let segmentId = subscription?.getEvaluatorCertification()?.getSegmentId();
    if (segmentId != null) {
      this.setState({ isLoadingSegment: true });
      getSegment(segmentId).then(segment => {
        let nextSubscriptionTierId = subscription.getNewSubscriptionTier()?.getId() ?? subscription.getSubscriptionTier().getId();
        this.setState({
          segment,
          isLoadingSegment: false,
          selectedSubscriptionTierId: segment.getSubscriptionTiers().find(tier => tier.getId() === nextSubscriptionTierId)?.getId()
        });
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve subscription tiers. Please try again.');
        this.setState({ isLoadingSegment: false, inChangeSubscriptionForSubscriptionId: null });
      });
    }
    else {
      setError('Error: Something went wrong. Please try again.');
    }
  }

  changeSubscription = () => {
    confirm({
      title: 'Are you sure you would like to change your subscription?',
      body: 'Any subscription changes will not begin until the next billing cycle.',
      onConfirm: () => {
        // Change the subscription
        startConfirmLoading();
        changeSubscription({
          subscriptionId: this.state.inChangeSubscriptionForSubscriptionId,
          subscriptionTierId: this.state.selectedSubscriptionTierId
        }).then(() => {
          closeConfirm();
          setSuccess('Successfully changed your subscription.');
          this.exitChangeSubscription();
          this.retrieveSubscriptions();
        }).catch(error => {
          closeConfirm();
          setError(error ?? 'Error: Unable to change your subscription. Please try again.');
        });
      }
    });
  }

  exitChangeSubscription = () => {
    this.setState({ inChangeSubscriptionForSubscriptionId: null, selectedSubscriptionTierId: null, isLoadingSegment: false });
  }

  startCancelSubscription = (subscriptionId) => {
    confirm({
      title: 'Are you sure you would like to cancel your subscription?',
      body: 'If so, you will have the remainder of your current term to use up any remaining evaluation credits, but your subscription will not be renewed at the end of the term. You will no longer be able to perform ErgoAlgo™ evaluations for this segment and will no longer have access to Ergonars.',
      onConfirm: () => {
        // Cancel the subscription
        startConfirmLoading();
        cancelSubscription({ subscriptionId }).then(() => {
          closeConfirm();
          setSuccess('Successfully cancelled your subscription.');
          this.retrieveSubscriptions();
        }).catch(error => {
          closeConfirm();
          setError(error ?? 'Error: Unable to cancel subscription. Please try again.');
        });
      },
      danger: true
    });
  }

  startResumeSubscription = (subscriptionId) => {
    confirm({
      title: 'Are you sure you would like to resume your subscription?',
      body: 'If so, you will be billed at the end of this billing cycle as normal.',
      onConfirm: () => {
        // Cancel the subscription
        startConfirmLoading();
        resumeSubscription({ subscriptionId }).then(() => {
          closeConfirm();
          setSuccess('Successfully resumed your subscription.');
          this.retrieveSubscriptions();
        }).catch(error => {
          closeConfirm();
          setError(error ?? 'Error: Unable to resume subscription. Please try again.');
        });
      },
      danger: true
    });
  }

  handleSubscriptionTierChange = (e) => {
    this.setState({ selectedSubscriptionTierId: e.target.value });
  }

  render() {
    return (
      <>
        <Container>
          {/* Page Header */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '.5em', marginTop: '1.5em' }}>My Billing</Typography>
          </div>

          {/* Subscriptions */}
          <>
            <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Your Subscriptions</Typography>

            {this.state.subscriptions == null || this.state.subscriptions.length === 0 && <Typography variant='body1' style={{ marginBottom: '15px' }}>You do not have any active subscriptions</Typography>}
            {this.state.subscriptions?.map((subscription, index) => (
              <Paper key={index} elevation={3} style={{ padding: '20px', marginBottom: '15px' }}>
                <Typography variant='h3' style={{ marginBottom: '0.5em' }}>{subscription.getEvaluatorCertification()?.getSegment()?.getName()} - {subscription.getSubscriptionTier()?.getName()}</Typography>
                {subscription.getIsPendingCancellation() && <Typography variant='body1'><b>Subscription Ends On:</b> {subscription.getSubscriptionEnd()?.toLocaleString()}</Typography>}
                {subscription.getNewSubscriptionTier() != null && <Typography variant='body1'><b>Changing Subscription:</b> Billing {subscription.getNewSubscriptionTier()?.getMonthlyCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} for {subscription.getNewSubscriptionTier()?.getName()} Tier on {subscription.getSubscriptionEnd()?.toLocaleString()}</Typography>}
                {!subscription.getIsPendingCancellation() && subscription.getNewSubscriptionTier() == null && <Typography variant='body1'><b>Renewal Date:</b> Billing {subscription.getSubscriptionTier()?.getMonthlyCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} on {subscription.getSubscriptionEnd()?.toLocaleString()}</Typography>}
                <Typography variant='body1'><b>Remaining Included Evaluations:</b> {subscription.getNumEvaluationsRemaining()}</Typography>

                {this.state.inChangeSubscriptionForSubscriptionId &&
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  {this.state.isLoadingSegment ? (
                    <CircularProgress size={25} />
                  ) : (
                    <RadioGroup
                      value={this.state.selectedSubscriptionTierId}
                      onChange={this.handleSubscriptionTierChange}
                      style={{ marginBottom: '20px' }}>
                      {this.state.segment.getSubscriptionTiers()?.sort((a, b) => a.getMonthlyCost() - b.getMonthlyCost())?.map((subscriptionTier, index) => (
                        <FormControlLabel
                          key={index}
                          value={subscriptionTier.getId()}
                          control={<Radio />}
                          label={subscriptionTier.getName() + ' (' + subscriptionTier.getMonthlyCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' / mo, ' + subscriptionTier.maxNumEvaluations + ' evaluations / mo)'}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </div>}

                <Grid container spacing={1} style={{ marginTop: '0.5em' }}>
                  {this.state.inChangeSubscriptionForSubscriptionId === subscription.getId() ? (
                    <>
                      <Grid item xs={12} md={4}>
                        <Button secondary onClick={this.exitChangeSubscription}>Cancel</Button>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Button danger onClick={this.changeSubscription} disabled={
                          this.state.isLoadingSegment || (this.state.selectedSubscriptionTierId == (subscription.getNewSubscriptionTier()?.getId() ?? subscription.getSubscriptionTier()?.getId()))
                        }>Save Change</Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {!subscription.getIsPendingCancellation() &&
                      <Grid item xs={12} md={4}>
                        <Button secondary onClick={() => this.startChangeSubscription(subscription.getId())}>Change Subscription</Button>
                      </Grid>}

                      <Grid item xs={12} md={4}>
                        {subscription.getIsPendingCancellation() ? (
                          <Button danger onClick={() => this.startResumeSubscription(subscription.getId())}>Resume Subscription</Button>
                        ) : (
                          <Button danger onClick={() => this.startCancelSubscription(subscription.getId())}>Cancel Subscription</Button>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            ))}
          </>

          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

          {/* Billing */}
          {this.state.person &&
          <>
            <Typography variant='h2'>Payment Method</Typography>
            <BillingWidget />
          </>}
        </Container>
      </>
    );
  }
}

MyBilling.propTypes = {
  isFullyAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    isFullyAuthenticated: state.auth.isFullyAuthenticated
  };
};

export default connect(mapStateToProps, null)(MyBilling);
