import React, { Component } from 'react';
import { Checkbox, Container, FormControlLabel, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

// Components
import { Button } from '@lexcelon/react-util';

// API
import { getBackgroundCheck, initiateBackgroundCheck } from '../../../api';

// Alerts
import { setError, setSuccess, clearErrors } from '../../../alerts';

class Checkr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeToBackgroundCheck: false,
      isLoading: false,
      redirect: false
    };
  }

  componentDidMount() {
    getBackgroundCheck().then(backgroundCheck => {
      if (backgroundCheck != null) {
        setSuccess('You have already initiated a background check.');
        this.setState({ redirect: true });
      }
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve evaluator.');
    });
  }

  componentWillUnmount() {
    clearErrors();
  }

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  hasValidForm = () => {
    if (!this.state.agreeToBackgroundCheck) {
      setError('Error: You must agree to the background check to become an evaluator.');
      return false;
    }

    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const hasValidForm = this.hasValidForm();

    if (!hasValidForm) {
      return;
    }

    this.setState({ isLoading: true });
    initiateBackgroundCheck().then(() => {
      setSuccess('Successfully initiated background check.');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to initiate background check.');
      this.setState({ isLoading: false });
    });
  };

  render() {
    return this.state.redirect ? <Redirect to='/' /> : (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Background Check</Typography>
        <Typography variant='body1' style={{ textAlign: 'center', marginTop: '3em' }}>
          In order for you to perform evaluations on behalf of Ergonauts, you must pass a background check. We use a third party, Checkr, to perform this background check. Once you submit this form, Checkr will reach out to you over email to begin this process. You must pass the background check in order to become an Independent Ergonaut™ Evaluator.
        </Typography>
        <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em', marginTop: '3em' }}>
          <FormControlLabel
            control={
              <Checkbox
                required
                disabled={this.state.isLoading}
                checked={this.state.agreeToBackgroundCheck}
                onChange={this.onCheckboxChange}
                name='agreeToBackgroundCheck'
              />
            }
            label='I consent to be contacted by Checkr to begin the background check process.'
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
            <Button type='submit' isLoading={this.state.isLoading} style={{ width: '49%' }}>
              Submit
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

export default Checkr;
