import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Checkbox, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';

// API
import { createEvaluator, updateEvaluator } from '../api';

// Alerts
import { clearErrors, setSuccess, setError } from '../alerts';

// Components
import { Button } from '@lexcelon/react-util';
import AddressInput from './AddressInput';

// Constants
import { MAX_FILE_SIZE_MB } from '@ergonauts/ergo-algo-react/core/constants';

class EvaluatorProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doingBusinessAsName: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      rangeOfTravel: '',
      receiveReferrals: false,
      notes: '',
      independentLogoUrl: '',
      isLoading: false,
      distance: ''
    };
  }

  componentDidMount() {
    if (this.props.evaluator != null) {

      this.setState({
        doingBusinessAsName: this.props.evaluator.getDoingBusinessAsName() ?? '',
        address: {
          line1: this.props.evaluator.getWorkAddress()?.getLine1() ?? '',
          line2: this.props.evaluator.getWorkAddress()?.getLine2() ?? '',
          city: this.props.evaluator.getWorkAddress()?.getCity() ?? '',
          state: this.props.evaluator.getWorkAddress()?.getState() ?? '',
          zip: this.props.evaluator.getWorkAddress()?.getZip() ?? ''
        },
        rangeOfTravel: this.props.evaluator.getRangeOfTravel() ?? '',
        receiveReferrals: this.props.evaluator.getReceiveReferrals(),
        notes: this.props.evaluator.getNotes() ?? ''
      });
    }

    const travelRange = this.props.evaluator?.getRangeOfTravel();
    if (travelRange != 10 && travelRange != 20 && travelRange != 30 && travelRange != 50) {
      this.setState({ distance: this.props.evaluator?.getRangeOfTravel() });
    }

    if (window && window.google) {
      var address1Field = document.querySelector('#line1');
      this.setState({
        autocomplete: new window.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components'],
          types: ['address'],
        })
      }, () => {
        this.state.autocomplete.addListener('place_changed', this.fillInAddress);
      });
    }

    this.fillInAddress = this.fillInAddress.bind(this);
  }

  componentWillUnmount() {
    clearErrors();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  fillInAddress() {
    if (this.state.autocomplete) {
      // Get the place details from the autocomplete object.
      const place = this.state.autocomplete.getPlace();
      let address1 = '';
      var city = '';
      var state = '';
      var zip = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code':
            zip = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          default:
        }
      }

      this.setState({ address: { line1: address1, city: city, state: state, zip: zip } });

      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      var address2Field = document.querySelector('#line2');
      address2Field.focus();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const {
      address
    } = this.state;

    var form = {
      ...Object.assign({}, this.state),
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip };

    this.setState({ isLoading: true });
    if (this.props.evaluator == null) {
      createEvaluator(form).then((evaluator) => {
        this.setState({ isLoading: false });
        setSuccess('Successfully created your Ergonaut™ profile!');
        this.props.onSuccess(evaluator);
      }).catch(error => {
        setError(error ?? 'Error: Unable to create your profile, please try again.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateEvaluator(form).then((evaluator) => {
        this.setState({ isLoading: false });
        setSuccess('Successfully updated Evaluator!');
        this.props.onSuccess(evaluator);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  onImageInput = (e) => {
    // Set Image for Display
    var input = document.getElementById('independentLogoUrl');
    // get file size in megabytes, throw error if file is too large
    var size = input.files[0].size / 1e6;
    if (size >= MAX_FILE_SIZE_MB) {
      setError(`Error: Please select a file smaller than ${MAX_FILE_SIZE_MB}MB.`);
      this.setState({ independentLogoUrl: '' });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });

    var fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    this.setState({ displayImage: input.files[0] });
    fReader.onloadend = function (event) {
      document.getElementById('displayImage').innerHTML = '<image src=\'' + event.target.result + '\' style=\'height: 60px;\'/>';
    };
  };

  formChanged() {
    return !(((this.props.evaluator.getDoingBusinessAsName() === null && this.state.doingBusinessAsName === '') || (this.props.evaluator.getDoingBusinessAsName() == this.state.doingBusinessAsName)) &&
    this.props.evaluator.getWorkAddress()?.getLine1() === this.state.address.line1 &&
    ((this.props.evaluator.getWorkAddress()?.getLine2() === null && this.state.address.line2 === '') || (this.props.evaluator.getWorkAddress()?.getLine2() == this.state.address.line2)) &&
    this.props.evaluator.getWorkAddress()?.getCity() === this.state.address.city &&
    this.props.evaluator.getWorkAddress()?.getState() === this.state.address.state &&
    this.props.evaluator.getWorkAddress()?.getZip() === this.state.address.zip &&
    this.props.evaluator.getRangeOfTravel() == this.state.rangeOfTravel &&
    this.props.evaluator.getReceiveReferrals() === this.state.receiveReferrals &&
    this.props.evaluator.getNotes() === this.state.notes &&
    this.state.independentLogoUrl === '');
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>

        {/* Doing Business As */}
        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '1em' }}>Independent Evaluator Preferences</Typography>
        <TextField
          disabled={this.state.isLoading}
          name="doingBusinessAsName"
          label="DBA (Doing Business As) Name"
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.doingBusinessAsName}
          onChange={this.onChange}
          variant='filled'
        />

        <Typography style={{ marginBottom: '10px', marginTop: '1em' }}>Logo (this will appear on evaluation reports)</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '0.3em', marginBottom: '0.3em' }}>
          <input disabled={this.state.isLoading} type="file" accept='.png,.jpg,.jpeg' id="independentLogoUrl" name="independentLogoUrl" ref={ref=> this.fileInput = ref} label="Select Image" onChange={this.onImageInput} style={{ width: '60%' }} value={this.state.independentLogoUrl} />
          <div id="displayImage">
            <Avatar />
          </div>
        </div>
        <Typography variant='button' style={{ color: 'grey' }}>PNG and JPG only</Typography>

        {/* ----- WORK ADDRESS ----- */}
        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Work Address</Typography>

        <AddressInput
          isLoading={this.state.isLoading}
          onChange={(address) => this.setState({ address })}
          requiredFields={['line1', 'city', 'state', 'zip']}
          initialValue={this.props.evaluator != null ? {
            line1: this.props.evaluator?.getWorkAddress()?.getLine1() ?? '',
            line2: this.props.evaluator?.getWorkAddress()?.getLine2() ?? '',
            city: this.props.evaluator?.getWorkAddress()?.getCity() ?? '',
            state: this.props.evaluator?.getWorkAddress()?.getState() ?? '',
            zip: String(this.props.evaluator?.getWorkAddress()?.getZip()) ?? '',
          } : undefined}
        />

        {/* ----- EVALUATOR PREFERENCES ----- */}
        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '1em' }}>Evaluator Preferences</Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.receiveReferrals}
              onChange={this.onCheckboxChange}
              name="receiveReferrals"
            />
          }
          disabled={this.state.isLoading}
          label="I would like to receive evaluation requests from Ergonauts."
        />
        <Typography style={{ marginBottom: '10px', marginTop: '1em' }} />

        <Typography variant='b1' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Max distance you are willing to travel from your work address to perform an evaluation?*</Typography>
        <RadioGroup
          value={this.state.rangeOfTravel}
          name='rangeOfTravel'
          onChange={this.onChange}
          style={{ marginBottom: '0.5em' }}>
          <FormControlLabel disabled={this.state.isLoading} value={10} control={<Radio />} label='Up to 10 miles' />
          <FormControlLabel disabled={this.state.isLoading} value={20} control={<Radio />} label='Up to 20 miles' />
          <FormControlLabel disabled={this.state.isLoading} value={30} control={<Radio />} label='Up to 30 miles' />
          <FormControlLabel disabled={this.state.isLoading} value={50} control={<Radio />} label='Up to 50 miles' />
          <FormControlLabel disabled={this.state.isLoading} value={this.state.distance} control={<Radio />} label={
            <TextField
              name='distance'
              label='Up to __ miles'
              type='number'
              value={this.state.distance}
              onChange={(e) => {
                this.setState({ distance: e.target.value });
                this.setState({ rangeOfTravel: e.target.value });
              }}
              disabled={this.state.isLoading}
              onFocus={() => this.setState({ rangeOfTravel: this.state.distance })}
            />
          } />
        </RadioGroup>

        <TextField
          required
          disabled={this.state.isLoading}
          name="notes"
          label="Evaluator Certifications and Description"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.notes}
          onChange={this.onChange}
          variant='filled'
          multiline
          rows={6}
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {this.props.closeForm &&
          <Button secondary onClick={this.props.closeForm} disabled={this.state.isLoading} style={{ width: '49%' }}>
            Cancel
          </Button>}
          <Button type='submit' isLoading={this.state.isLoading} style={{ width: '49%' }} disabled={this.props.evaluator != null && !this.formChanged()}>
            Save Evaluator Profile
          </Button>
        </div>
      </form>
    );
  }
}

EvaluatorProfileForm.propTypes = {
  closeForm: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  evaluator: PropTypes.object
};

export default EvaluatorProfileForm;
