import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Container, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// API
import { getEvaluator, getSegment } from '../../../../../api';

// Alerts
import { setError, clearErrors } from '../../../../../alerts';

// Icons
import { FaUserCircle, FaChalkboardTeacher, FaPencilRuler } from 'react-icons/fa';

// Components
import StepSection from './StepSection';
import { Button } from '@lexcelon/react-util';

// Styling
const styles = theme => ({
  pageTitle: {
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '1.5em'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  hoverText: {
    textAlign: 'center',
    marginTop: '1em'
  }
});

const HOVER_TEXT = [
  'We ask you to create your account and set up two-factor authentication in order to ensure you are a real human.',
  'Select and purchase the training you are interested in to become a certified Ergonaut™ Evaluator.',
  'Complete our proprietary, interactive training modules to prepare you to become a certified Ergonaut™ Evaluator.',
  'Provide us with additional personal information on your certifications and skills and let us know how far you are willing to travel to perform evaluations.'
];

class SegmentSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segment: null,
      isEvaluatorProfileComplete: false,
      hoverIndex: null,
      // hasInitiatedBackgroundCheck: false,
      isLoadingEvaluator: false,
      isLoadingSegment: false
    };
  }

  componentDidMount() {
    const segmentId = this.props.match?.params?.id;

    // Retrieve segment information
    this.setState({ isLoadingSegment: true });
    getSegment(segmentId).then(segment => {
      this.setState({ isLoadingSegment: false, segment: segment });
    }).catch(error => {
      this.setState({ isLoadingSegment: false });
      setError(error ?? 'Error: Unable to retrieve segment info.');
    });

    // Check if evaluator profile is complete already
    this.setState({ isLoadingEvaluator: true });
    getEvaluator().then((evaluator) => {
      this.setState({ isLoadingEvaluator: false, isEvaluatorProfileComplete: evaluator.workAddressId != null });
    }).catch(() => {
      this.setState({ isLoadingEvaluator: false });
    });

    // // Check if bakground check has been initiated already
    // this.setState({ isLoadingBackgroundCheck: true });
    // getBackgroundCheck().then(backgroundCheck => {
    //   this.setState({ isLoadingBackgroundCheck: false });
    //   if (backgroundCheck != null) this.setState({ hasInitiatedBackgroundCheck: true });
    // }).catch(error => {
    //   this.setState({ isLoadingBackgroundCheck: false });
    //   setError(error ?? 'Error: Unable to check if background check has been initiated.');
    // });
  }

  componentWillUnmount() {
    clearErrors();
  }

  render() {
    const { classes } = this.props;
    return (
      <Container>
        <Typography variant='h1' className={classes.pageTitle}>Become an {this.state.segment?.getName()} Evaluator</Typography>

        {/* Step Sections */}
        <Grid container className={classes.cardContainer} columnSpacing={3} rowSpacing={3}>
          <StepSection step={1} name='Establish An Account' to='/account/account-settings' hoverText={HOVER_TEXT[0]} completed />
          <StepSection
            step={2}
            name='Purchase Training and Subscription'
            to={'/evaluator/segments/' + this.props.match?.params?.id + '/purchase'}
            hoverText={HOVER_TEXT[1]}
            Icon={FaChalkboardTeacher}
            completed={this.state.segment?.getHasPurchasedTraining() && this.state.segment?.getHasActiveSubscription()}
            isLoading={this.state.isLoadingSegment}
          />
          <StepSection
            step={3}
            name='Complete Your Training'
            to='/training/training-module/1'
            hoverText={HOVER_TEXT[2]}
            Icon={FaPencilRuler}
            completed={this.state.segment?.getHasMetSegmentRequirements()}
            disabled={!this.state.segment?.getHasPurchasedTraining() || !this.state.segment?.getHasActiveSubscription()}
            isLoading={this.state.isLoadingSegment}
          />
          <StepSection
            step={4}
            name='Complete Your Evaluator Profile'
            to='/evaluator/create-profile'
            hoverText={HOVER_TEXT[3]}
            Icon={FaUserCircle}
            completed={this.state.isEvaluatorProfileComplete}
            isLoading={this.state.isLoadingEvaluator}
            disabled={!this.state.segment?.getHasMetSegmentRequirements()}
          />
        </Grid>

        <Button component={Link} to={'/evaluator/segments/' + this.props.match?.params?.id} secondary sx={{ marginTop: '20px' }}>Learn more about ErgoAlgo™ Office</Button>
      </Container>
    );
  }
}

SegmentSignUp.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(SegmentSignUp));
