import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { setError } from '../alerts';

import { getPerson, getSegment } from '../api';
import { CircularProgress } from '@mui/material';

// PLACEHOLDER
var segmentId = 1;

class HomeRedirect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginRedirect: false,
      person: null,
      isGroupAdmin: false,
      isPersonLoading: true,
      isSegmentLoading: true,
      groupId: null,
      hasMetSegmentRequirements: false,
      hasPurchasedTrainingAndSubscription: false,
      isEvaluatorProfileComplete: false
    };
  }

  componentDidMount() {
    if (this.state.person?.getId() == null) {
      this.retrievePerson();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personId != this.state.person?.getId() && this.props.personId != null) {
      this.retrievePerson();
    }
  }

  retrievePerson = () => {
    this.setState({ isPersonLoading: true });
    getPerson().then((person) => {
      if (person.getEvaluator()?.getWorkAddress() != null) this.setState({ isEvaluatorProfileComplete: true });
      this.setState({ person: person }, () => {
        this.sortGroupAssociations();
        this.setState({ isPersonLoading: false });
      });
      this.retrieveSegmentRequirements();
    }).catch(() => {
      this.setState({ loginRedirect: true, isPersonLoading: false });
    });
  }

  sortGroupAssociations() {
    this.state.person?.getGroupAssociations()?.forEach(groupAssociation => {
      // added similar conditions to the server to make sure the front end doesn't freak out if status is sent
      if (groupAssociation.getRole()?.getRole() == 'ADMINISTRATOR' && groupAssociation.getInvitationStatus() == 'ACCEPTED' && groupAssociation.getIsActive() == 1) {
        this.setState({ isGroupAdmin: true, groupId: groupAssociation.getGroupId() });
      }
    });
  }

  retrieveSegmentRequirements() {
    getSegment(segmentId).then(segment => {
      this.setState({
        hasMetSegmentRequirements: segment.getHasMetSegmentRequirements(),
        hasPurchasedTrainingAndSubscription: segment.getHasPurchasedTraining() && segment.getHasActiveSubscription(),
        isCoveredByGroup: segment.getIsCoveredByGroup(),
        isSegmentLoading: false
      });
    }).catch((error) => {
      setError(error ?? 'Error: Unable to retrieve segment info.');
      this.setState({ isSegmentLoading: false });
    });
  }

  render() {
    if (!this.state.isPersonLoading && this.state.loginRedirect) {
      return (<Redirect to='/auth/login' />);
    }
    else if (!(this.state.isPersonLoading || this.state.isSegmentLoading)) {
      if (this.state.isGroupAdmin) {
        return (<Redirect to={'/groups/' + this.state.groupId} />);
      }
      else if (this.state.isCoveredByGroup && (!this.state.isEvaluatorProfileComplete || !this.state.hasMetSegmentRequirements)) {
        return (<Redirect to={'/evaluator/segments/' + segmentId + '/steps/group'} />);
      }
      else if ((this.state.person?.evaluatorInterest || this.state.person?.getEvaluator() != null) && (
        !this.state.isEvaluatorProfileComplete ||
        !this.state.hasPurchasedTrainingAndSubscription ||
        !this.state.hasMetSegmentRequirements) &&
        !this.state.isCoveredByGroup) {
        return (<Redirect to={'/evaluator/segments/' + segmentId + '/steps/'} />);
      }
      else if (this.state.isEvaluatorProfileComplete) {
        return (<Redirect to='/evaluator/evaluations' />);
      }
      else {
        return (<Redirect to='/evaluee/evaluations' />);
      }
    }
    else {
      return (
        <div style={{ flex: 1, minHeight: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      );
    }
  }
}

HomeRedirect.propTypes = {
  personId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  personId: state.auth.personId,
});

export default connect(mapStateToProps, null)(HomeRedirect);
