import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography, TextField, Divider, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

// Server
import { updatePassword, getPerson, deleteCurrentPerson } from '../../../api';

// Redux
import { connect } from 'react-redux';
import { setError, setSuccess } from '../../../alerts';

// Components
import { Button, LineItem } from '@lexcelon/react-util';
import { EvaluatorProfileForm, PersonForm } from '../../../components';
import { GENDER_OPTIONS } from '@ergonauts/ergo-algo-react/core/constants';
import { clearCookies } from '../../../redux/actions/authActions';

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: null,
      inEditProfileMode: false,
      inEditEvaluatorInfoMode: false,
      inChangePasswordMode: false,
      deleteAccountModalOpen: false,
      deleteAccountConfirmText: '',
      deleteAccountConfirmPassword: '',
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      gender: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
      isLoading: false,
      isLoadingDeletion: false
    };
  }

  componentDidMount(){
    if (this.props.isFullyAuthenticated) {
      this.retrievePerson();
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.isFullyAuthenticated != this.props.isFullyAuthenticated && this.props.isFullyAuthenticated) {
      this.retrievePerson();
    }
  }

  retrievePerson = () => {
    this.setState({ isLoading: true });
    getPerson().then((person) => {
      this.setState({ person: person, isLoading: false });
    }).catch((error) => {
      this.setState({ isLoading: false });
      setError(error ?? 'Error: Could not retrieve person.');
    });
  }

  startEditProfileInfoMode = () => {
    // Turn on edit mode
    this.setState({ inEditProfileInfoMode: true });
  }

  exitEditProfileInfoMode = () => {
    // Clear the form and turn off edit mode
    this.setState({ inEditProfileInfoMode: false });
    this.retrievePerson();
  }

  startEditEvaluatorInfoMode = () => {
    this.setState({ inEditEvaluatorInfoMode: true });
  }

  onEditEvaluatorSuccess = () => {
    this.setState({ inEditEvaluatorInfoMode: false });
    this.retrievePerson();
  }


  startChangePasswordMode = () => {
    // Turn on change password mode
    this.setState({ inChangePasswordMode: true });
  }

  exitChangePasswordMode = () => {
    // Clear the form and turn off change password mode
    this.setState({
      inChangePasswordMode: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    });
  }


  openDeleteAccountModal = () => {
    // Turn on change password mode
    this.setState({ deleteAccountModalOpen: true });
  }

  exitDeleteAccountModal = (e) => {
    if (this.state.isLoadingDeletion) {
      e.preventDefault();
      return;
    }
    // Clear the form and turn off change password mode
    this.setState({
      deleteAccountModalOpen: false,
      deleteAccountConfirmText: '',
      deleteAccountConfirmPassword: ''
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  onChangePassword = (e) => {
    e.preventDefault();

    if (this.state.currentPassword === '' || this.state.newPassword === '' || this.state.confirmNewPassword === '') {
      setError('Error: You cannot leave a field blank.');
    }

    else if (this.state.newPassword === this.state.confirmNewPassword) {
      this.setState({ isLoading: true });
      updatePassword({ currentPassword: this.state.currentPassword, newPassword: this.state.newPassword }).then(() => {
        this.setState({ isLoading: false, inChangePasswordMode: false });
        setSuccess('Successfully updated password!');
        this.exitChangePasswordMode();
      }).catch(error => {
        this.setState({ isLoading: false });
        setError('Error: ' + error
          ? error
          : 'Could not change your password.');
      });
    }
    else {
      setError('Error: Passwords must match.');
    }
  }

  onDeleteSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoadingDeletion: true });
    deleteCurrentPerson(this.state.deleteAccountConfirmPassword)
      .then(() => {
        this.props.clearCookies();
        setSuccess('Account deleted.');
      })
      .catch((error) => setError(error))
      .finally(() => this.setState({ isLoadingDeletion: false }));
  }

  render() {
    return (
      <Container>
        {/* Page Header */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '.5em', marginTop: '1.5em' }}>{this.state.inEditMode ? 'Edit Account Settings' : 'My Account'}</Typography>
        </div>

        {/* Profile Section - View */}
        {!this.state.inEditProfileInfoMode &&
        <div>
          <Typography variant='h2'>Profile Info</Typography>

          {this.state.isLoading ? <CircularProgress /> :
            <div>
              <LineItem
                value={this.state.person?.getFullName()}
                description='Name'
              />

              <LineItem
                value={this.state.person?.getEmail()}
                description='Email'
              />

              <LineItem
                value={this.state.person?.getPhoneNumber()}
                description='Phone Number'
              />

              <LineItem
                value={GENDER_OPTIONS.find(e => e.enum == this.state.person?.getGender())?.display}
                description='Gender'
              />

              <LineItem
                value={this.state.person?.getMailingAddress()?.getFullAddress()}
                description='Mailing Address'
              />

              <Button onClick={this.startEditProfileInfoMode} style={{ width: 250 }} secondary>Edit Profile</Button>
            </div>}
        </div>}

        {/* Profile Section */}
        {this.state.inEditProfileInfoMode &&
          <div>
            <PersonForm
              onClose={() => this.setState({ inEditProfileInfoMode: false })}
              onSuccess={this.exitEditProfileInfoMode}
              person={this.state.person}
            />
          </div>
        }

        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

        {/* Evaluator Section - View */}
        {(this.state.person?.getEvaluator()?.getWorkAddress() != null || this.state.isLoading) &&
        <>
          {!this.state.inEditEvaluatorInfoMode &&
            <div>
              <Typography variant='h2'>Evaluator Info</Typography>

              {this.state.isLoading ? <CircularProgress /> :
                <div>
                  <div style={{ display: 'flex', justifyContent: 'left', marginTop: '0.5em', marginBottom: '0.3em' }}>
                    <img src={this.state.person?.getEvaluator()?.getIndependentLogoUrl()} style={{ width: '150px', height: 'auto' }} />
                  </div>

                  <LineItem
                    value={this.state.person?.getEvaluator()?.getDoingBusinessAsName()}
                    description='DBA (Doing Business As) Name'
                  />
                  <LineItem
                    value={this.state.person?.getEvaluator()?.getWorkAddress()?.getFullAddress()}
                    description='Work Address'
                  />

                  <LineItem
                    value={this.state.person?.getEvaluator()?.getRangeOfTravel()}
                    description='Range of Travel'
                  />

                  <LineItem
                    value={this.state.person?.getEvaluator().getReceiveReferrals() ? 'Yes' : 'No'}
                    description='Receiving Referrals'
                  />

                  <LineItem
                    value={this.state.person?.getEvaluator()?.getNotes()}
                    description='Additional Certifications'
                  />

                  <Button onClick={this.startEditEvaluatorInfoMode} style={{ width: 250 }} secondary>Edit Profile</Button>
                </div>}
            </div>}

          {/* Evaluator Section */}
          {this.state.inEditEvaluatorInfoMode &&
            <div>
              <EvaluatorProfileForm
                closeForm={() => this.setState({ inEditEvaluatorInfoMode: false })}
                onSuccess={this.onEditEvaluatorSuccess}
                evaluator={this.state.person?.getEvaluator()}
              />
            </div>}

          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        </>
        }

        {/* Certifications */}
        {this.state.person?.getEvaluator() != null || this.state.isLoading &&
        <>
          <Typography variant='h2' style={{ marginBottom: '0.5em' }}>My Certifications</Typography>
          {this.state.isLoading ? <CircularProgress /> :
            <Button component={Link} to={'/evaluator/certifications'} style={{ width: 250 }}>View Certifications</Button>}
          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        </>}

        {/* Group Associations Section */}
        {this.state.person?.getGroupAssociations()?.filter(association => !association.getGroup()?.getIsIndependent()).length != 0 &&
          <>
            <Typography variant='h2'>Group Associations</Typography>
            {this.state.isLoading ? <CircularProgress /> : this.state.person?.getGroupAssociations()?.filter(association => !association.getGroup()?.getIsIndependent()).map((groupAssociation, index) => {
              return (
                <div key={index}>
                  <LineItem
                    value={groupAssociation.getGroup()?.getName() + ' ' + groupAssociation.getRole()?.getRole()}
                  />
                  {groupAssociation.getRole()?.getRole() == 'ADMINISTRATOR' ? (
                    <Button component={Link} to={'/groups/' + groupAssociation.getGroup()?.getId()} style={{ width: 250 }}>View Group</Button>
                  ) : (
                    <Button component={Link} to={'/evaluator/segments/1/steps/group'} style={{ width: 250 }}>View ErgoAlgo™ Office Steps</Button>
                  )}
                </div>
              );
            })}
            <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
          </>
        }

        {/* My Evaluations Section */}
        <Button component={Link} to='/evaluee/evaluations' secondary style={{ width: 250, marginBottom: '50px' }}>
          View Evaluations Performed On Me
        </Button>

        {/* Password Section */}
        {!this.state.inChangePasswordMode &&
          <div style={{ marginBottom: '50px' }}>
            <Typography variant='h2' style={{ marginBottom: '15px' }}>Password</Typography>

            <Button onClick={this.startChangePasswordMode} style={{ width: 250 }} danger>
              Change Password
            </Button>
          </div>
        }

        {/* Delete Account Section */}
        {!this.state.inChangePasswordMode &&
          <div style={{ marginBottom: '50px' }}>
            <Typography variant='h2' style={{ marginBottom: '15px' }}>Delete Account</Typography>

            <Button onClick={this.openDeleteAccountModal} style={{ width: 250 }} danger>
              Delete Account
            </Button>
          </div>
        }

        {/* Change password mode content */}
        {this.state.inChangePasswordMode &&
        <div>
          <form autoComplete="off" onSubmit={this.onChangePassword} style={{ marginBottom: '5em' }}>
            {/* ----- CHANGE PASSWORD ----- */}
            <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Change Password</Typography>

            {/* Old Password Input */}
            <TextField
              required
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.currentPassword === ''}
              name="currentPassword"
              label="Current Password"
              type='password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.currentPassword}
              onChange={this.onChange}
              variant='filled'
            />

            {/* New Password Input */}
            <TextField
              required
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.newPassword === ''}
              name="newPassword"
              label="New Password"
              type='password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.newPassword}
              onChange={this.onChange}
              variant='filled'
            />

            {/* Confirm New Password Input */}
            <TextField
              required
              disabled={this.state.isLoading}
              error={this.state.formError && this.state.confirmNewPassword === ''}
              name="confirmNewPassword"
              label="Confirm New Password"
              type='password'
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.confirmNewPassword}
              onChange={this.onChange}
              variant='filled'
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
              <Button secondary onClick={this.exitChangePasswordMode} disabled={this.state.isLoading} style={{ width: '49%' }}>
                Cancel
              </Button>
              <Button type='submit' isLoading={this.state.isLoading} style={{ width: '49%' }}>
                Change Password
              </Button>
            </div>
          </form>
        </div>
        }

        {/* Delete Account modal */}
        <Dialog
          open={this.state.deleteAccountModalOpen}
          onClose={this.exitDeleteAccountModal}
        >
          <DialogTitle>
            Delete Account
          </DialogTitle>

          <DialogContent>
            <form onSubmit={this.onDeleteSubmit}>
              <DialogContentText style={{
                WebkitUserSelect: 'none',
                WebkitTouchCallout: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none'
              }}>
              Are you sure you would like to delete your account? You will no longer be able to log in,
              view your evaluations, or perform evaluations. Any subscriptions you have will end. You cannot undue this.
              To confirm, please enter the text &quot;Delete Account&quot; below and confirm your password.
              </DialogContentText>
              <TextField
                required
                disabled={this.state.isLoadingDeletion}
                error={this.state.formError && this.state.deleteAccountConfirmText === ''}
                name="deleteAccountConfirmText"
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.deleteAccountConfirmText}
                onChange={this.onChange}
                variant='filled'
              />
              <TextField
                required
                disabled={this.state.isLoadingDeletion}
                error={this.state.formError && this.state.deleteAccountConfirmPassword === ''}
                name="deleteAccountConfirmPassword"
                label="Confirm Password"
                type='password'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.deleteAccountConfirmPassword}
                onChange={this.onChange}
                variant='filled'
              />

              <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
                <Button secondary disabled={this.state.isLoadingDeletion} onClick={this.exitDeleteAccountModal} style={{ width: '49%' }}>
                Cancel
                </Button>
                <Button danger type='submit' disabled={this.state.isLoadingDeletion || (this.state.deleteAccountConfirmText !== 'Delete Account' && ((this.state.deleteAccountConfirmPassword?.length ?? 0) === 0))} isLoading={this.state.isLoadingDeletion} style={{ width: '49%' }} >
                Delete Account
                </Button>
              </Container>
            </form>
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
}

AccountSettings.propTypes = {
  isFullyAuthenticated: PropTypes.bool.isRequired,
  clearCookies: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    isFullyAuthenticated: state.auth.isFullyAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCookies: () => clearCookies(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
