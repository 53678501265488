import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Icons
import { FaCheckCircle } from 'react-icons/fa';

// Constants
import { GROOVY_GREEN, TEXT_BLACK, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

const styles = theme => ({
  container: {
    backgroundColor: TEXT_WHITE,
    margin: '10px',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px 10px 0px',
      padding: '5px',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  imageAndTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '5px',
    width: 'auto'
  },
  image: {
    height: '150px',
    objectFit: 'contain',
    margin: '5px',
    [theme.breakpoints.down('sm')]: {
      height: '100px'
    }
  },
  titleAndType: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    margin: '5px'
  },
  icon: {
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '5px'
    }
  }
});

class TrainingItemWidget extends Component {
  render() {
    const { classes } = this.props;
    const getContents = () => (
      <div className={classes.container}>
        <div className={classes.imageAndTextContainer}>
          {/* Image */}
          {this.props.trainingItem?.getDisplayImageUrl() &&
              <img src={this.props.trainingItem?.getDisplayImageUrl()} className={classes.image} />}

          {/* Title and Type */}
          <div className={classes.titleAndType}>
            <Typography variant='h4' style={{ marginLeft: '10px' }}>{this.props.trainingItem?.getName()}</Typography>
            <Typography variant='subtitle1' style={{ marginLeft: '10px' }}>{this.props.trainingItem?.className() === 'TrainingModule' ? 'Training Module' : this.props.trainingItem.className()}</Typography>
          </div>
        </div>

        {/* Icon */}
        {this.props.trainingItem.className() == 'TrainingModule' && this.props.trainingItem.getIsCompleted()  &&
            <div className={classes.icon}>
              <FaCheckCircle size={50} color={GROOVY_GREEN} />
            </div>
        }
      </div>
    );

    return (
      <div>
        {this.props.trainingItem.getHasAccess() ? (
          <Link to={(this.props.trainingItem.className() === 'TrainingModule' ? '/training/training-module/' : '/training/ergonar/') + this.props.trainingItem.getId()} style={{ textDecoration: 'none', color: TEXT_BLACK }}>
            { getContents() }
          </Link>
        ) : (
          <div>
            { getContents() }
          </div>
        )}
      </div>
    );
  }
}
TrainingItemWidget.propTypes = {
  trainingItem: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(TrainingItemWidget);
