import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setError } from '../../../alerts';
import { softAuthenticatePerson } from '../../../redux/actions/authActions';

// Components
import { CustomLogin } from '@lexcelon/react-util';

// Images
import { ColorLogo } from '@ergonauts/ergo-algo-react/core/images';

// Constants
import { ERGO_BLUE } from '@ergonauts/ergo-algo-react/core/constants';

class Login extends Component {
  render() {
    return (
      <CustomLogin
        {...this.props}
        color={ERGO_BLUE}
        logo={ColorLogo}
        onLogin={this.props.onLogin}
        redirect={this.props.isAuthenticated}
        createAccountLink='/auth/create-account'
        resetPasswordLink='/auth/forgot-password'
        redirectLink={'/auth/login/redirect'}
        setError={setError}
        isLoading={this.props.isLoading}
      />
    );
  }
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isLoading
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.personId != null && !state.auth.isLoading,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (email, password) => softAuthenticatePerson(dispatch, email, password)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
