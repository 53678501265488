import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Components
import { Button } from '@lexcelon/react-util';

// Constants
import { TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

// Icons
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const styles = theme => ({
  container: {
    backgroundColor: TEXT_WHITE,
    margin: '10px',
    padding: '10px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 10px 0px',
      padding: '5px',
    }
  }
});

class ErgonarWidget extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {/* Image and Title */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {this.props.ergonar?.getDisplayImageUrl() &&
            <img src={this.props.ergonar?.getDisplayImageUrl()} style={{ width: '75px', height: '50px', objectFit: 'contain' }} />}
          <Typography variant='h5' style={{ marginLeft: '10px' }}>{this.props.ergonar?.getName()}</Typography>
        </div>

        {/* Date and Time */}
        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <Typography variant='body1'>{this.props.ergonar?.getDateTime()?.toLocaleString({ weekday: 'long', month: 'short', day: 'numeric' })}</Typography>
          <Typography variant='body1'>@ {this.props.ergonar?.getDateTime()?.toLocaleString({ ...DateTime.TIME_SIMPLE, timeZoneName: 'short' })}</Typography>
        </div>

        {/* Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            {this.props.ergonar.getHasRSVP() && <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
              <CalendarTodayIcon />
              <Typography variant='body1' style={{ marginLeft: '5px', fontStyle: 'italic' }}>RSVPd</Typography>
            </div>}
          </div>
          <Button component={Link} to={'/training/ergonar/' + this.props.ergonar.getId()}>Details</Button>
        </div>
      </div>
    );
  }
}
ErgonarWidget.propTypes = {
  ergonar: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ErgonarWidget);
