import axios from 'axios';
import { clearCookies } from '../redux/actions/authActions';

// Library
import { BackgroundCheck, Coupon, ErgoAlgoSegment, EvaluatorCertificationSubscription, Ergonar, Evaluation, Evaluator, EvaluatorCertification, Group, GroupAssociation, Person, TrainingModule, TrainingModuleVersion } from '@ergonauts/ergo-algo-react/core/lib';

const axiosWithCredentials = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_SERVER_URL
});

axiosWithCredentials.interceptors?.response?.use(response => {
  return Promise.resolve(response?.data != null ? (response.data === '' ? null : response.data) : response);
}, error => {
  if (error?.response?.status === 401) clearCookies();
  return Promise.reject(error?.response?.data?.message || error?.response?.data || error);
});

export function loginPerson({ email, password }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/login', { email: email, password: password }).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function logoutPerson() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/logout').then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createPerson({ firstName, lastName, phoneNumber, email, gender, address, password, evaluatorInterest, heardAboutFrom }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/person/', {
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      address,
      password,
      evaluatorInterest,
      heardAboutFrom
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function resendWelcomeEmail(email) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/resend-welcome', { email }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

// need to update this to always pass in personId. ex: person/3
export function getPerson(id = null) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/person/' + (id ?? '')).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteCurrentPerson(password) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/redact', { password }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUpcomingErgonars({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/ergonar/upcoming', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Ergonar.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listVideoErgonars({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/ergonar/video', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Ergonar.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getErgonar(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/ergonar/' + id).then(res => {
      resolve(Ergonar.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function rsvpErgonar({ ergonarId, rsvp }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/ergonar/rsvp', {
      ergonarId,
      rsvp
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listActiveTrainingModules({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/training-module', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: TrainingModule.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTrainingModule(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/training-module/' + id).then(res => {
      resolve(TrainingModule.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTrainingModuleVersions(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/training-module/' + id + '/versions').then(res => {
      resolve(TrainingModuleVersion.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function startTrainingModuleVersion(trainingModuleVersionId) {
  return new Promise((resolve, reject) => {
    axiosWithCredentials.post('/evaluator/training/in-progress', {
      trainingModuleVersionId: trainingModuleVersionId
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function requestForgotPassword(email) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/forgot-password/request', { email: email }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function confirmForgotPassword({ token, password }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/forgot-password/confirm', { token: token, password: password }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function updatePerson({ firstName, lastName, phoneNumber, email, gender, address }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/person/', {
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      address
    }).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updatePassword({ currentPassword, newPassword }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/update-password', {
      currentPassword: currentPassword,
      newPassword: newPassword
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function changeEmailConfirm(token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/change-email/confirm', { token: token }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getEvaluator() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/evaluator/').then(res => {
      resolve(Evaluator.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createEvaluator({ line1, line2, city, state, zip, rangeOfTravel, receiveReferrals, notes, doingBusinessAsName, displayImage }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();

    data.append('rangeOfTravel', rangeOfTravel);
    data.append('receiveReferrals', receiveReferrals);
    data.append('address', JSON.stringify({ line1: line1, line2: line2, city: city, state: state, zip: zip }));
    data.append('notes', notes);

    if (doingBusinessAsName != null) data.append('doingBusinessAsName', doingBusinessAsName);
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.post('/evaluator/', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(Evaluator.thaw(res));
    }).catch(error => {
      reject(error);
    });


    /*
    axiosWithCredentials.post('/evaluator/', {
      rangeOfTravel,
      receiveReferrals,
      address: {
        line1,
        line2,
        city,
        state,
        zip
      },
      notes
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
    */
  });
}

export function listSegments({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/segment', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: ErgoAlgoSegment.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSegment(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/segment/' + (id ?? '')).then(res => {
      resolve(ErgoAlgoSegment.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getCoupon(code) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/coupon/' + code).then(res => {
      resolve(Coupon.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updatePaymentMethod(paymentMethodId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/payment-method', { paymentMethodId: paymentMethodId }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getPaymentMethod() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/stripe/payment-method').then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getPurchaseInfo({ items, couponCode = null }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/purchase/information', {
      items,
      couponCode
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function purchaseTraining({ segmentId, couponCode }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/purchase/training', {
      segmentId,
      couponCode
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function purchaseErgonar({ ergonarId, couponCode }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/purchase/ergonar', {
      ergonarId,
      couponCode
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function purchaseSubscription({ segmentId, subscriptionTierId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/purchase/subscription', {
      segmentId,
      subscriptionTierId
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function cancelSubscription({ subscriptionId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/subscription/' + subscriptionId +'/cancel', {
      subscriptionId,
      cancel: true
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function resumeSubscription({ subscriptionId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/subscription/' + subscriptionId +'/cancel', {
      subscriptionId,
      cancel: false
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function changeSubscription({ subscriptionId, subscriptionTierId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/stripe/subscription/' + subscriptionId + '/change-tier', {
      subscriptionId,
      subscriptionTierId
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function deletePaymentMethod(paymentMethodId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.delete('/stripe/payment-method', {
      data: {
        paymentMethodId
      }
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluatorCertifications({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluator/certification', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: EvaluatorCertification.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getEvaluatorCertification(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/evaluator/certification/' + id).then(res => {
      resolve(EvaluatorCertification.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function requestEvaluation({ firstName, lastName, email, address, phoneNumber, phoneType }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/evaluation/request', {
      firstName,
      lastName,
      email,
      address,
      phoneNumber,
      phoneType
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function confirmRegistrationEmail(token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/confirm', {
      token: token
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function scheduleEvaluation({ groupId, evaluatorId, segmentId, scheduledDateTime, evaluee, address, evalueeCanViewReport }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/evaluation/', {
      groupId,
      evaluatorId,
      segmentId,
      scheduledDateTime,
      evaluee,
      address,
      evalueeCanViewReport
    }).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateEvaluation({ id, segmentId, evaluatorId, scheduledDateTime, evaluee, address, evalueeCanViewReport }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch('/evaluation/' + id, {
      segmentId,
      evaluatorId,
      scheduledDateTime,
      evaluee,
      address,
      evalueeCanViewReport
    }).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteEvaluation(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.delete('/evaluation/' + id).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getEvaluation(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/evaluation/' + id).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluations({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluator/evaluation', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluationCertificationCertificates({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluator/certification/certificates', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: EvaluatorCertification.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateEvaluator({ doingBusinessAsName, line1, line2, city, state, zip, rangeOfTravel, receiveReferrals, notes, displayImage }) {
  return new Promise(function(resolve, reject) {

    var data = new FormData();

    data.append('rangeOfTravel', rangeOfTravel);
    data.append('receiveReferrals', receiveReferrals);
    data.append('address', JSON.stringify({ line1: line1, line2: line2, city: city, state: state, zip: zip }));
    data.append('notes', notes);
    data.append('doingBusinessAsName', doingBusinessAsName);

    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.patch('/evaluator/', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(Evaluator.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvalueePreviousEvaluations({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluee/evaluation/previous', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvalueeUpcomingEvaluations({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluee/evaluation/upcoming', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluatorGroups({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluator/group', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Group.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getGroup(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/group/' + id).then(res => {
      resolve(Group.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTokenGroupInvitation(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/group/invitation/${token}/`).then(res => {
      resolve(GroupAssociation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function acceptGroupInvitation(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/group/invitation/${token}/accept/`).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function declineGroupInvitation(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/group/invitation/${token}/decline/`).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupAdminAssociations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/group/' + groupId + '/admins', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: GroupAssociation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupEvaluatorAssociations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/group/' + groupId + '/evaluators', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: GroupAssociation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupEvaluatorEligibleSegments({ groupId, evaluatorId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/group/' + groupId + '/evaluator/' + evaluatorId + '/segment/eligible', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: ErgoAlgoSegment.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupEvaluations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/group/' + groupId + '/evaluations', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupInvitationAssociations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/group/' + groupId + '/invitations', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: GroupAssociation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTokenEvaluation(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/evaluation/invitation/' + token).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function acceptEvaluationInvitation(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/evaluation/invitation/accept/' + token).then(res => {
      resolve(res);
    }).catch(error =>{
      reject(error);
    });
  });
}

export function rejectEvaluationInvitation(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/evaluation/invitation/reject/' + token).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function resendEvalueeInvitation(evaluationId) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/evaluation/invitation/resend/' + evaluationId).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function initiateBackgroundCheck() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/background-check').then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getBackgroundCheck() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/background-check').then(res => {
      resolve(BackgroundCheck.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function setupMultiFactorAuthentication() {
  return new Promise(function(resolve,  reject) {
    axiosWithCredentials.get('/multi-factor/setup').then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function confirmMultiFactorAuthentication(token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/confirm', { token }).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function verifyMultiFactorAuthentication(token) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/verify', { token }).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getMultiFactorStatus() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/multi-factor/status').then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listSubscriptions({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/stripe/subscriptions', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: EvaluatorCertificationSubscription.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function regenerateReport(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/evaluation/' + id + '/generate-report').then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function sendTwoFactorVerification(type) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/send', { type }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function checkTwoFactorVerification(code) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/multi-factor/verify', { code }).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}
