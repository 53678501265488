import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header } from '@lexcelon/react-util';
import { WhiteLogo } from '@ergonauts/ergo-algo-react/core/images';
import { ERGO_BLUE, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

// Redux
import { connect } from 'react-redux';
import { logoutPerson } from '../redux/actions/authActions';

// Alerts
import { setError } from '../alerts';

// API
import { getPerson, getSegment } from '../api';

const LOGGED_OUT_LINKS = [
  {
    slug: '/auth/login',
    text: 'Login'
  },
  {
    slug: '/auth/create-account',
    text: 'Create Account'
  }
];

const EVALUEE_LINKS = [
  {
    slug: '/evaluee/evaluations',
    text: 'My Evaluations'
  }
];

const NOT_INDEPENDENT_EVALUATOR_LINKS = [
  {
    slug: '/evaluator/segments/1/steps',
    text: 'Become an Independent Evaluator'
  }
];

const GROUP_EVALUATOR_LINKS = [
  {
    slug: '/evaluator/segments/1/steps/group',
    text: 'Become a Company Evaluator'
  }
];

const EVALUATOR_LINKS = [
  {
    slug: '/evaluator/evaluations',
    text: 'My Evaluations'
  }
];

const LOGGED_IN_LINKS = [
  {
    slug: '/training',
    text: 'Training & Ergonars'
  },
  {
    slug: '/evaluator/segments',
    text: 'ErgoAlgo™ Certification Segments'
  }
];

const ACCOUNT_LINKS = [
  {
    slug: '/account/account-settings',
    text: 'My Account'
  }
];

const BILLING_LINKS = [
  {
    slug: '/account/billing',
    text: 'My Billing'
  }
];

const ADMIN_LINKS = [
  {
    slug: '/admin',
    text: 'Go to Admin Portal'
  }
];

class MyAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupAdminLinks: [],
      isCoveredByGroup: false,
      hasMetSegmentRequirements: false,
      isEvaluatorProfileComplete: false,
      hasPurchasedTrainingAndSubscription: false
    };
  }

  componentDidMount() {
    this.getAppropriateLinks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSoftAuthenticated !== this.props.isSoftAuthenticated || prevProps.isFullyAuthenticated !== this.props.isFullyAuthenticated) {
      this.getAppropriateLinks();
    }
  }

  getAppropriateLinks = () => {
    if (this.props.isFullyAuthenticated) {
      getPerson().then(person => {
        if (person.getEvaluator()?.getWorkAddress() != null) this.setState({ isEvaluatorProfileComplete: true });

        let adminGroups = [];
        let groupAdminLinks = [];
        person?.getGroupAssociations()?.forEach(association => {
          if (association.getRole()?.getRole() === 'ADMINISTRATOR' && adminGroups.find(group => group.getId() === association.getGroup()?.getId()) == null) {
            adminGroups.push(association.getGroup());
            groupAdminLinks = [
              {
                slug: '/groups/' + association.getGroup().getId(),
                text: association.getGroup().getName() + ' Overview'
              },
              ...groupAdminLinks
            ];
          }
        });
        this.setState({ groupAdminLinks });
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve group associations.');
      });

      getSegment(1).then(segment => {
        this.setState({
          hasMetSegmentRequirements: segment.getHasMetSegmentRequirements(),
          isCoveredByGroup: segment.getIsCoveredByGroup(),
          hasPurchasedTrainingAndSubscription: segment.getHasPurchasedTraining() && segment.getHasActiveSubscription()
        });
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve segment info.');
      });
    }
  }

  render() {
    let links = [];
    if (!this.props.isFullyAuthenticated) links = LOGGED_OUT_LINKS;
    else {

      let showBecomeAGroupEvaluatorLink = this.state.isCoveredByGroup && (!this.state.isEvaluatorProfileComplete || !this.state.hasMetSegmentRequirements);
      let showBecomeAnIndependentEvaluatorLink = !this.state.hasPurchasedTrainingAndSubscription || !this.state.isEvaluatorProfileComplete || !this.state.hasMetSegmentRequirements;
      let showEvaluatorEvaluationLink = this.state.hasMetSegmentRequirements && this.state.isEvaluatorProfileComplete && (this.state.hasPurchasedTrainingAndSubscription || this.state.isCoveredByGroup);
      let showMyEvaluationsLink = !showEvaluatorEvaluationLink;
      let showAdminLink = this.props.isAdmin;

      links = [
        ...(showMyEvaluationsLink ? [...EVALUEE_LINKS, null] : []),
        ...(showEvaluatorEvaluationLink ? EVALUATOR_LINKS : []),
        ...(showBecomeAGroupEvaluatorLink ? GROUP_EVALUATOR_LINKS : []),
        ...(showBecomeAnIndependentEvaluatorLink ? NOT_INDEPENDENT_EVALUATOR_LINKS : []),
        ...LOGGED_IN_LINKS,
        null,
        ...(this.state.groupAdminLinks?.length > 0 ? [...this.state.groupAdminLinks, null] : []),
        ...ACCOUNT_LINKS,
        ...BILLING_LINKS,
        ...(showAdminLink ? [null, ...ADMIN_LINKS] : [])
      ];
    }

    return (
      <Header
        drawer={this.props.isFullyAuthenticated}
        logo={WhiteLogo}
        logoDescription='Ergonauts Logo'
        links={links}
        backgroundColor={ERGO_BLUE}
        textColor={TEXT_WHITE}
        onLogout={this.props.isFullyAuthenticated ? this.props.logoutPerson : null}
        isLoading={this.props.isLoading}
        onOpen={this.getAppropriateLinks}
      />
    );
  }
}

MyAppBar.propTypes = {
  logoutPerson: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isSoftAuthenticated: PropTypes.bool.isRequired,
  isFullyAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isSoftAuthenticated: state.auth.isSoftAuthenticated,
  isFullyAuthenticated: state.auth.isFullyAuthenticated,
  isAdmin: state.auth.isAdmin,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = dispatch => ({
  logoutPerson: () => logoutPerson(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAppBar);
