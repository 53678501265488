import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';


// API
import { getSegment } from '../../../../../api';

// Alerts
import { setError } from '../../../../../alerts';

// Components
import { PurchaseWidget } from '../../../../../components';

class PurchaseSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segment: null,
      redirect: false,
      selectedSubscriptionTierId: null,
      isLoading: false
    };
  }

  componentDidMount() {
    this.determinePurchaseItems();
  }

  determinePurchaseItems = () => {
    const segmentId = this.props.match?.params?.id;
    getSegment(segmentId).then(segment => {
      if (segment.getHasPurchasedTraining() && segment.getHasActiveSubscription()) {
        setError('Error: You have already purchased training for this segment and have an active subscription.');
        this.setState({ redirect: true });
      }
      else this.setState({ segment: segment, selectedSubscriptionTierId: segment.getSubscriptionTiers()?.find(tier => tier.getName() === 'Entry')?.getId() });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve segment information.');
    });
  }

  onSubmit = () => {
    this.setState({ isLoading: true });
  }

  onError = () => {
    this.setState({ isLoading: false });
  }

  onRedirect = () => {
    this.setState({ redirect: true });
  }

  onSuccess = () => {
    this.setState({ redirect: true });
  }

  handleSubscriptionTierChange = (e) => {
    this.setState({ selectedSubscriptionTierId: e.target.value });
  }

  render() {
    // Get list of items for purchase
    let purchaseItems = [];
    if (this.state.selectedSubscriptionTierId != null) {
      if (this.state.segment != null) {
        if (!this.state.segment?.getHasPurchasedTraining()) purchaseItems.push({
          type: 'Training',
          id: this.props.match?.params?.id
        });
        if (!this.state.segment?.getHasActiveSubscription()) purchaseItems.push({
          type: 'Subscription',
          id: this.props.match?.params?.id,
          subscriptionTierId: this.state.selectedSubscriptionTierId
        });
      }
    }

    return this.state.redirect ? <Redirect to='/' /> : (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', margin: '1em 0px 1em 0px' }}>{this.state.segment?.getName()} Certification</Typography>

        {this.state.segment != null &&
        <div>
          <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Select Subscription Tier</Typography>
          <RadioGroup
            value={this.state.selectedSubscriptionTierId}
            onChange={this.handleSubscriptionTierChange}
            style={{ marginBottom: '20px' }}>
            {this.state.segment.getSubscriptionTiers()?.sort((a, b) => a.getMonthlyCost() - b.getMonthlyCost())?.map((subscriptionTier, index) => (
              <FormControlLabel
                disabled={this.state.isLoading}
                key={index}
                value={subscriptionTier.getId()}
                control={<Radio />}
                label={subscriptionTier.getName() + ' (' + subscriptionTier.getMonthlyCost()?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' / mo, ' + subscriptionTier.maxNumEvaluations + ' evaluations / mo)' }
              />
            ))}
          </RadioGroup>

          {purchaseItems.length > 0 &&
          <PurchaseWidget
            items={purchaseItems}
            onSuccess={this.onSuccess}
            onError={this.onError}
            onSubmit={this.onSubmit}
            onRedirect={this.onRedirect}
          />}
        </div>}
      </Container>
    );
  }
}

PurchaseSegment.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(PurchaseSegment);
