import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Components
import { Button } from '@lexcelon/react-util';

// Constants
import { LIGHT_GREY } from '@ergonauts/ergo-algo-react/core/constants';

// Styles
const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: LIGHT_GREY,
    borderRadius: 10,
    padding: '20px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '20px 0 20px 0',
      marginBottom: '10px 0 10px 0',
    }
  },
  imageAndInfo: {
    display: 'flex',
    flexDirection: 'row'
  },
  image: {
    width: '150px',
    height: '200px',
    objectFit: 'contain'
  },
  titleAndDescription: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 1em 0 1em',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1em 0 0'
    }
  },
  title: {
    marginBottom: '0.25em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2em'
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  }
});

class SegmentWidget extends Component {
  render () {
    const { classes } = this.props;
    return (
      <div className={classes.container}>

        <div className={classes.imageAndInfo}>
          {/* Image */}
          <img src={this.props.ergoAlgoSegment?.getDisplayImageUrl()} className={classes.image} />

          {/* Title and description */}
          <div className={classes.titleAndDescription}>
            <Typography variant='h1' className={classes.title}>{this.props.ergoAlgoSegment?.getName()}</Typography>
            <Typography variant='body1'>{this.props.ergoAlgoSegment?.getShortDescription()}</Typography>
            {/* Button */}
            <div className={classes.buttonContainer}>
              <Button component={Link} to={'/evaluator/segments/' + this.props.ergoAlgoSegment?.getId()} style={{ marginTop: '1em', width: 200 }}>
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SegmentWidget.propTypes = {
  ergoAlgoSegment: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(SegmentWidget);
