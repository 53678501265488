import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Navigation from './navigation';
import ScrollToTop from './ScrollToTop';
import { ThemeProvider } from '@mui/material';
import theme from '@ergonauts/ergo-algo-react/core/theme';
import AppBar from './navigation/AppBar';
import Footer from './navigation/Footer';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line no-undef
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', minHeight: '100vh' }}>
              <AppBar />
              <Navigation />
              <Footer />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Elements>
  );
}

export default App;
