import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthRedirect from './AuthRedirect';

// Redux
import { connect } from 'react-redux';
import { checkAuthentication } from '../redux/actions/authActions';
import ErrorPage from '../components/ErrorPage';

class PageWrapper extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidMount() {
    this.props.checkAuthentication(this.props.isSoftAuthenticated, this.props.isFullyAuthenticated);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSoftAuthenticated !== this.props.isSoftAuthenticated || prevProps.isFullyAuthenticated !== this.props.isFullyAuthenticated) {
      this.props.checkAuthentication(this.props.isSoftAuthenticated, this.props.isFullyAuthenticated);
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // write API call here to log this, and email it to lexcelon
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return AuthRedirect(this.props.location, this.props.authProps) || this.props.children;
  }
}

PageWrapper.propTypes = {
  authProps: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.any,
  checkAuthentication: PropTypes.func.isRequired,
  isSoftAuthenticated: PropTypes.bool.isRequired,
  isFullyAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  authProps: state.auth,
  isSoftAuthenticated: state.auth.isSoftAuthenticated,
  isFullyAuthenticated: state.auth.isFullyAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: (isSoftAuthenticated, isFullyAuthenticated) => checkAuthentication(dispatch, isSoftAuthenticated, isFullyAuthenticated)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageWrapper));
