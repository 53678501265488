import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, MenuItem, TextField, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../alerts';

// API
import { getTrainingModule, getTrainingModuleVersions, startTrainingModuleVersion } from '../../../api';

// Componenets
import { LineItem } from '@lexcelon/react-util';

import Unity, { UnityContext } from 'react-unity-webgl';
import qs from 'qs';

import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

class TrainingModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingModule: null,
      trainingModuleVersions: null,
      trainingModuleLoadingProgression: null,
      unityContext: null,
      error: null,
      isLoading: true
    };
  }

  componentDidMount() {
    // Warn users on mobile
    if (window?.innerWidth < 1250) alert('Warning: If you are on a mobile device or tablet, the training module may not function as expected. Please switch to a laptop or desktop for a better experience.');

    this.setState({ isLoading: true });
    const trainingModuleId = this.props.match?.params?.id;
    let selectedTrainingModuleVersionId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.versionId;
    getTrainingModule(trainingModuleId).then(trainingModule => {
      this.setState({ trainingModule: trainingModule });

      getTrainingModuleVersions(trainingModuleId).then((trainingModuleVersions) => {
        this.setState({ trainingModuleVersions: trainingModuleVersions });
        let foundVersion = false;

        // load the specified version if valid
        let trainingModuleVersion = trainingModuleVersions.find(e => e.getId() == selectedTrainingModuleVersionId);
        if (trainingModuleVersion) {
          this.loadTrainingModule(trainingModuleVersion.getId());
          foundVersion = true;
          return;
        }

        // get the most recent started version
        for (let i = 0; i < trainingModuleVersions.length; i++) {
          let trainingModuleVersion = trainingModuleVersions[i];
          let evaluatorTrainings = trainingModuleVersion.getEvaluatorTrainings();

          if (trainingModuleVersion.getId() == selectedTrainingModuleVersionId) {
            this.loadTrainingModule(trainingModuleVersion.getId());
            foundVersion = true;
            break;
          }

          if (evaluatorTrainings.length != 0) {
            this.loadTrainingModule(trainingModuleVersion.getId());
            foundVersion = true;
            break;
          }
        }

        if (!foundVersion) {
          this.loadTrainingModule(trainingModuleVersions[0].getId());
        }
      }).catch(error => {
        this.setState({ error: error ?? 'Error: Unable to retrieve training module.' });
        this.setState({ isLoading: false });
      });
    }).catch(error => {
      this.setState({ error: error ?? 'Error: Unable to retrieve training module.' });
      this.setState({ isLoading: false });
    });
  }

  componentDidUpdate() {
    this.state.unityContext?.on('progress', (progression) => {
      this.setState({
        trainingModuleLoadingProgression: progression * 100
      });
    });
  }

  changeActiveTrainingModule = (e) => {
    const trainingModuleId = this.props.match?.params?.id;
    let trainingModuleVersionId = e.target.value;
    // do whatever server thing we need to do to initiate a new training module version
    // i think just create a new evaluator training record

    startTrainingModuleVersion(trainingModuleVersionId).then(() => {
      // reload the page
      window.location.href = '/training/training-module/' + trainingModuleId + '?versionId=' + trainingModuleVersionId;
    });
  }

  loadTrainingModule = (trainingModuleVersionId) => {
    let trainingModuleVersion = this.state.trainingModuleVersions.find((trainingModuleVersion) => trainingModuleVersion.getId() == trainingModuleVersionId);
    let version = trainingModuleVersion.getSemanticVersion();

    let unityContext = new UnityContext({
      loaderUrl: 'https://ergoalgotraining.blob.core.windows.net/training-storage/' + version + '/Build/Ergonauts.loader.js',
      dataUrl: 'https://ergoalgotraining.blob.core.windows.net/training-storage/' + version + '/Build/Ergonauts.data.gz',
      frameworkUrl: 'https://ergoalgotraining.blob.core.windows.net/training-storage/' + version + '/Build/Ergonauts.framework.js.gz',
      codeUrl: 'https://ergoalgotraining.blob.core.windows.net/training-storage/' + version + '/Build/Ergonauts.wasm.gz',
      streamingAssetsUrl: 'https://ergoalgotraining.blob.core.windows.net/training-storage/' + version + '/StreamingAssets'
    });

    this.setState({ activeTrainingModuleVersionId: trainingModuleVersionId, isLoading: false, unityContext: unityContext });
  }

  render() {
    if (this.state.error != null) {
      setError(this.state.error);
      return <Redirect to='/training' />;
    }

    if (this.state.isLoading) {
      return <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress />
      </div>;
    }

    return (
      <>
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          {this.state.trainingModuleLoadingProgression != 100 && <CircularProgress variant="determinate" value={this.state.trainingModuleLoadingProgression} />}
        </div>

        <Container style={{ marginBottom: '50px' }}>
          {/* Header  */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '50px 0px 25px 0px' }}>
            {this.state.trainingModule?.getDisplayImageUrl() &&
              <img src={this.state.trainingModule?.getDisplayImageUrl()} style={{ width: '100px', height: 'auto', objectFit: 'contain', border: '1px black solid', marginRight: '20px' }} />}

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
              <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.5em' }}>{this.state.trainingModule?.getName()} Training Module</Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '10px' }}>
            <TextField
              select
              name="version"
              label="Version"
              style={{ width: '100%', marginBottom: '5px' }}
              value={this.state.activeTrainingModuleVersionId}
              onChange={this.changeActiveTrainingModule}
              variant='filled'>
              {this.state.trainingModuleVersions?.map((trainingModuleVersion, i) => (
                <MenuItem key={i} value={trainingModuleVersion.getId()}>
                  {
                    'Version ' + trainingModuleVersion.getSemanticVersion() +
                      ' [' + (trainingModuleVersion.getEvaluatorTrainings().length == 0 ? 'Not Started' : enumToDisplayString(trainingModuleVersion?.getEvaluatorTrainings()[0]?.getStatus())) + ']'
                  }
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
            <Unity
              unityContext={this.state.unityContext}
              style={{ width: '80vw', height: 'calc(80vw * 9 / 16)', border: '2px solid grey', backgroundColor: 'lightgrey' }}
            />
          </div>

          <LineItem
            value={this.state.trainingModule?.getDescription()}
            description='Description'
          />
        </Container>
      </>
    );
  }
}

TrainingModule.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(TrainingModule);
