import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from '@stripe/react-stripe-js';

// Lib
import { Ergonar, ErgoAlgoSegment } from '@ergonauts/ergo-algo-react/core/lib';

// APIs
import { getPaymentMethod, updatePaymentMethod, getPurchaseInfo, purchaseErgonar, purchaseTraining, purchaseSubscription } from '../api';

// Components
import { Button, LineItem } from '@lexcelon/react-util';

// Alerts
import { clearErrors, setError, setSuccess } from '../alerts';

// Constants
const TYPES = {
  TRAINING: 'Training',
  SUBSCRIPTION: 'Subscription',
  ERGONAR: 'Ergonar'
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 30
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  descriptorColumn: {
    width: '70%',
    padding: 5,
    alignItems: 'center'
  },
  priceColumn: {
    width: '30%',
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  lineItem: {
    marginTop: 0,
    marginBottom: 0
  }
});

function isEqual(items1, items2) {
  if (items1?.length !== items2?.length) return false;
  for (let i = 0; i < items1.length; i++) {
    let match = Object.keys(items1[i]).length === Object.keys(items2[i]).length && Object.keys(items1[i]).every(p => items1[i][p] === items2[i][p]);
    if (!match) return false;
  }
  return true;
}

class ItemRow extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.itemRow}>
        <div className={classes.descriptorColumn}>
          <LineItem
            description={this.props.type}
            value={this.props.description}
            style={{ marginTop: 0, marginBottom: 0 }}
          />
        </div>

        <div className={classes.priceColumn}>
          <Typography variant='body1'>{this.props.value?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}</Typography>
        </div>
      </div>
    );
  }
}
ItemRow.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.number
};
const ItemRowWidget = withStyles(styles, { withTheme: true })(ItemRow);

class PurchaseWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayInfo: null,
      couponCode: '',
      isCouponUsed: false,
      isLoading: false,
      isLoadingInfo: false,
      isLoadingPaymentMethod: false,
      useNewPaymentMethod: false,
      paymentMethod: null,
      zip: '',
      cardName: ''
    };
  }

  componentDidMount() {
    this.getDisplayInfo();

    this.setState({ isLoadingPaymentMethod: true });
    getPaymentMethod().then(paymentMethod => {
      let paymentMethodExists = paymentMethod != null && Object.keys(paymentMethod)?.length > 0;
      this.setState({
        paymentMethod: paymentMethodExists ? paymentMethod : null,
        useNewPaymentMethod: !paymentMethodExists,
        isLoadingPaymentMethod: false
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to load existing payment method.');
      this.setState({ isLoadingPaymentMethod: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.getDisplayInfo();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitCouponCode = (e) => {
    e.preventDefault();

    // Refresh value on page
    this.getDisplayInfo();
  }

  onSubmitPurchase = () => {
    if (this.props.onSubmit != null) this.props.onSubmit();
    if (this.state.useNewPaymentMethod || this.state.paymentMethod == null) {
      // Check for missing info
      if (this.state.zip == null || this.state.zip === '') {
        setError('Error: You must enter a zip code.');
        return;
      }
      else if (this.state.cardName == null || this.state.cardName === '') {
        setError('Error: You must enter the cardholder\'s name.');
        return;
      }

      const { stripe, elements } = this.props;
      if (stripe == null || elements == null) {
        setError('Error: Something went wrong with Stripe. Please try again later.');
        return;
      }

      this.setState({ isLoading: true });

      // Disable the card inputs
      let cardNumberElement = elements.getElement('cardNumber');
      let cardExpiryElement = elements.getElement('cardExpiry');
      let cardCvcElement = elements.getElement('cardCvc');
      cardNumberElement?.update({ disabled: true });
      cardExpiryElement?.update({ disabled: true });
      cardCvcElement?.update({ disabled: true });

      // Submit the purchases
      stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: { // eslint-disable-line
          name: this.state.cardName
        }
      }).then(res => {
        if (res.error != null) throw res.error; // Stripe doesn't throw their own errors

        updatePaymentMethod(res?.paymentMethod?.id).then(() => {
          this.purchaseItems();
        }).catch(error => {
          cardNumberElement?.update({ disabled: false });
          cardExpiryElement?.update({ disabled: false });
          cardCvcElement?.update({ disabled: false });
          setError(error ?? 'Error: Unable to update payment method.');
          this.setState({ isLoading: false });
        });
      }).catch(error => {
        setError(error ?? 'Error: Unable to create payment method with Stripe.');
        this.setState({ isLoading: false });
        cardNumberElement?.update({ disabled: false });
        cardExpiryElement?.update({ disabled: false });
        cardCvcElement?.update({ disabled: false });
        if (this.props.onError != null) this.props.onError();
      });
    }
    else {
      this.setState({ isLoading: true });
      this.purchaseItems();
    }
  }

  purchaseItems() {
    let purchasePromises = [];
    this.props.items?.forEach(item => {
      let arg = {};
      if (this.state.couponCode != null && this.state.couponCode !== '') arg = { couponCode: this.state.couponCode };
      switch (item.type) {
        case TYPES.ERGONAR:
          purchasePromises.push(purchaseErgonar({ ...arg, ergonarId: item.id }));
          break;
        case TYPES.TRAINING:
          purchasePromises.push(purchaseTraining({ ...arg, segmentId: item.id }));
          break;
        case TYPES.SUBSCRIPTION:
          purchasePromises.push(purchaseSubscription({ ...arg, segmentId: item.id, subscriptionTierId: item.subscriptionTierId }));
          break;
      }
    });

    // Wait for all of the promises to resolve
    Promise.allSettled(purchasePromises).then(results => {

      // Loop through each resolved promise checking if successful or failed
      let errorMessages = [];
      results.forEach((result, index) => {
        let item = this.props.items[index];
        if (result.status === 'fulfilled') {
          setSuccess('Successfully purchased ' + item.type);
        }

        // If the item purchase failed, add an error message to the list
        else {
          errorMessages.push(result.reason + ' Please try again.' ?? 'Error: Unable to purchase ' + item.type + '. Please try again.');
        }
      });

      // Re-enable the stripe inputs
      const { stripe, elements } = this.props;
      if (stripe == null || elements == null) {
        setError('Error: Something went wrong with Stripe. Please try again later.');
        this.setState({ isLoading: false });
        return;
      }
      var cardNumberElement = elements.getElement('cardNumber');
      var cardExpiryElement = elements.getElement('cardExpiry');
      var cardCvcElement = elements.getElement('cardCvc');
      cardNumberElement?.update({ disabled: false });
      cardExpiryElement?.update({ disabled: false });
      cardCvcElement?.update({ disabled: false });

      // Show the relevant error messages here so the setSuccess doesn't delete them
      if (errorMessages.length > 0) {
        errorMessages.forEach(message => setError(message));
        this.props.onRedirect(); // Non-recoverable error
      }
      else {
        this.props.onSuccess();
        clearErrors();
      }
      this.setState({ isLoading: false });
    });
  }

  useNewPaymentMethod = () => {
    this.setState({ useNewPaymentMethod: true });
  }

  getDisplayInfo() {
    this.setState({ isLoadingInfo: true });
    getPurchaseInfo({ items: this.props.items, couponCode: this.state.couponCode ?? null }).then(displayInfo => {
      this.setState({
        displayInfo,
        isLoadingInfo: false,
        isCouponUsed: this.state.couponCode !== '' ? true : false
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve purchase info.');
      if (error?.toLowerCase()?.includes('coupon')) {
        if (this.props.onError != null) this.props.onError(); // Recoverable error
        this.setState({ couponCode: '' });
      }
      else this.props.onRedirect(); // Non-recoverable error
      this.setState({ isLoadingInfo: false });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.container} elevation={3} style={this.props.style} {...this.props}>
        {this.props.title != null &&
        <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '0.5em' }}>{this.props.title}</Typography>}

        {/* Item Row */}
        {this.state.displayInfo?.lineItems.map((lineItem, index) => {
          let description = '';
          let type = lineItem.type;
          let cost = 0;
          switch (lineItem.type) {
            case TYPES.ERGONAR: {
              let ergonar = Ergonar.thaw(lineItem.ergonar);
              description = ergonar?.getName();
              cost = ergonar.getPrice();
              break;
            }
            case TYPES.TRAINING: {
              let segment = ErgoAlgoSegment.thaw(lineItem.segment);
              description = segment.getName();
              if (segment.getTrainingBillingDescription() != null && segment.getTrainingBillingDescription() !== '') type += ' – [' + segment.getTrainingBillingDescription() + ']';
              cost = segment.getTrainingCost();
              break;
            }
            case TYPES.SUBSCRIPTION: {
              let segment = ErgoAlgoSegment.thaw(lineItem.segment);
              description = segment.getName();
              if (segment.getSubscriptionBillingDescription() != null && segment.getSubscriptionBillingDescription() !== '') type += ' – [' + segment.getSubscriptionBillingDescription() + ']';
              cost = segment.getMonthlySubscriptionCost();
              break;
            }
          }

          return (
            <ItemRowWidget
              key={index}
              description={description}
              type={type}
              value={cost}
            />
          );
        })}

        <Divider />

        <ItemRowWidget
          description='Subtotal'
          value={this.state.displayInfo?.subtotal}
        />

        {this.state.displayInfo?.savings != null && this.state.displayInfo?.savings !== 0 &&
        <ItemRowWidget
          description='Savings'
          value={-1 * this.state.displayInfo?.savings}
        />}

        <ItemRowWidget
          description='Taxes'
          value={this.state.displayInfo?.taxJar?.amount_to_collect}
        />

        <Divider />

        <ItemRowWidget
          description='Total'
          value={this.state.displayInfo?.total}
        />

        {/* Coupon */}
        <div style={{ marginTop: 20 }}>
          {this.state.isCouponUsed ? (
            <Typography variant='body1'>Coupon Used: {this.state.couponCode}</Typography>
          ) : (
            <form onSubmit={this.onSubmitCouponCode} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
              <TextField label='Coupon Code' variant='outlined' value={this.state.couponCode} name='couponCode' onChange={this.onChange} disabled={this.state.isLoading || this.state.isLoadingInfo || this.state.isLoadingPaymentMethod} />
              <Button type='submit' style={{ marginLeft: '10px', width: 100 }} disabled={this.state.couponCode == '' || this.state.isLoading || this.state.isLoadingPaymentMethod} isLoading={this.state.isLoadingInfo}>Submit</Button>
            </form>
          )}
        </div>

        {/* Credit Card Info */}
        <div>
          <Paper style={{ margin: 30, padding: 20 }} elevation={5}>

            {this.state.paymentMethod != null && !this.state.useNewPaymentMethod ? (
              <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                {/* Existing Payment Method */}
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                  <Typography variant='body1'>{this.state.paymentMethod?.card?.brand} ending in {this.state.paymentMethod?.card?.last4}</Typography>
                  <Typography variant='overline'>Expires on {this.state.paymentMethod?.card?.exp_month}/{this.state.paymentMethod?.card?.exp_year}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button style={{ width: 200 }} onClick={this.useNewPaymentMethod} disabled={this.state.isLoading || this.state.isLoadingInfo || this.state.isLoadingPaymentMethod}>Change Payment Method</Button>
                </div>
              </div>
            ) : (
              <div>
                {/* New Payment Method */}
                <Typography variant='body1' style={{ marginBottom: 10 }}>New Payment Method</Typography>
                <TextField label='Name on Card' name='cardName' value={this.state.cardName} onChange={this.onChange} style={{ width: '100%', marginBottom: 10 }} disabled={this.state.isLoading || this.state.isLoadingInfo || this.state.isLoadingPaymentMethod} />
                <div style={{ border: '2px solid #bcbcbc', padding: '17px 10px 17px 10px', borderRadius: 5, marginBottom: 10 }}>
                  <CardNumberElement options={inputStyleOptions} />
                </div>
                <div style={{ border: '2px solid #bcbcbc', padding: '17px 10px 17px 10px', borderRadius: 5, marginBottom: 10 }}>
                  <CardExpiryElement options={inputStyleOptions} />
                </div>
                <div style={{ border: '2px solid #bcbcbc', padding: '17px 10px 17px 10px', borderRadius: 5, marginBottom: 10 }}>
                  <CardCvcElement options={inputStyleOptions} />
                </div>
                <TextField label='Zip' name='zip' value={this.state.zip} onChange={this.onChange} style={{ width: '100%' }} disabled={this.state.isLoading || this.state.isLoadingInfo || this.state.isLoadingPaymentMethod} inputProps={{ maxLength: 5 }} />
              </div>
            )}
          </Paper>
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button style={{ width: 300, marginTop: '3em' }} onClick={this.onSubmitPurchase} isLoading={this.state.isLoading} disabled={this.state.isLoadingInfo || this.state.isLoadingPaymentMethod}>Submit Purchase</Button>
        </div>
      </Paper>
    );
  }
}
PurchaseWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired, // Call on successful purchase of all items
  onSubmit: PropTypes.func, // Call when the form submit button is pressed
  onRedirect: PropTypes.func.isRequired, // Call if error bad enough to redirect
  onError: PropTypes.func, // Call if there's a recoverable error
  title: PropTypes.string,
  style: PropTypes.object
};

const inputStyleOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Roboto',
      color: '#6b6b6b',
      height: '30px'
    }
  }
};

const WrappedPurchaseWidget = withStyles(styles, { withTheme: true })(PurchaseWidget);

const InjectedPurchaseWidget = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <WrappedPurchaseWidget elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedPurchaseWidget; //withStyles(styles, { withTheme: true })(PurchaseWidget);
