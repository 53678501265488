import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// API
import { listEvaluationCertificationCertificates } from '../../../api';

// Alerts
import { setError } from '../../../alerts';

import { Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Table } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

const COLUMNS = [
  {
    title: 'Segment',
    render: rowData => rowData?.segment?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.segment?.getName()?.toLowerCase().includes(term?.toLowerCase()),
    customSort: (a, b) => a.segment?.getName()?.localeCompare(b.segment?.getName()),
  },
  {
    title: 'Continuing Education Credits Awarded',
    render: rowData => rowData?.getContinuingEducationCredits(),
    customSort: (a, b) => a.getContinuingEducationCredits() - b.getContinuingEducationCredits(),
  },
  {
    title: 'Issue Date',
    render: rowData => rowData?.getCreatedAt()?.toLocaleString(FULL_DATE_TIME),
    customSort: (a, b) => a.getCreatedAt() - b.getCreatedAt(),
  },
  {
    title: 'Download Certificate',
    render: rowData =>
      <Button
        variant='contained'
        href={rowData.getCertificateUrl()}
        target='_blank'
        rel='noreferrer'>
        <DownloadIcon />
      </Button>
  },
];

class EvaluatorCertifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certifications: [],
      isLoading: false
    };
  }

  // server call returns the certification with the certificates nested inside
  // have to do a little work to add segment and certificate to the array
  componentDidMount() {
    var certs = [];
    this.setState({ isLoading: true });
    listEvaluationCertificationCertificates().then(({ results }) => {
      results.forEach(certification => {
        certification.getEvaluatorCertificationCertificates().forEach(certificate => {
          certificate['segment'] = certification.getSegment();
          certs.push(certificate);
        });
      });
      this.setState({ certifications: certs, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Could not load certifications');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Certifications</Typography>
        <Table
          title={''}
          data={this.state.certifications}
          columns={COLUMNS}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

EvaluatorCertifications.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(EvaluatorCertifications);
