import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { CircularProgress, Container, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// API
import { getErgonar, rsvpErgonar } from '../../../../api';

// Components
import { PurchaseWidget } from '../../../../components';
import { Button, LineItem } from '@lexcelon/react-util';
import ReactPlayer from 'react-player';

// Constants
import { BLUE, DARK_BLUE, FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';
const VIDEO = 'VIDEO';
const ZOOM = 'ZOOM';

// Styles
const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px 0px 50px 0px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '10px 10px 10px 10px',
    }
  },
  image: {
    width: '300px',
    height: 'auto',
    objectFit: 'contain',
    border: '1px black solid',
    marginRight: '20px' ,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px'
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: '10px'
    }
  },
  title: {
    textAlign: 'center',
    marginBottom: '0.25em',
  }
});

class Ergonar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoadingRSVP: false,
      redirect: false,
      inPurchaseMode: false
    };
  }

  componentDidMount() {
    this.loadErgonar();
  }

  loadErgonar = () => {
    this.setState({ isLoading: true });

    const ergonarId = this.props.match?.params?.id;
    getErgonar(ergonarId).then(ergonar => {
      this.setState({ ergonar: ergonar, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve ergonar.');
      this.setState({ isLoading: false, redirect: true });
    });
  }

  rsvpToErgonar = () => {
    if (this.state.ergonar?.getHasRSVP()) {
      setError('Error: You have already RSVPd.');
      return;
    }

    this.setState({ isLoadingRSVP: true });
    rsvpErgonar({ ergonarId: this.state.ergonar?.getId(), rsvp: true }).then(() => {
      setSuccess('Successfully RSVPd to ergonar.');
      this.loadErgonar();
      this.setState({ isLoadingRSVP: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to RSVP to ergonar.');
      this.setState({ isLoadingRSVP: false });
    });
  }

  onPurchaseSuccess = () => {
    this.setState({ inPurchaseMode: false });
    window?.location?.reload(false);
  }

  onPurchaseRedirect = () => {
    this.setState({ redirect: true });
  }

  render() {
    const { classes } = this.props;
    if (this.state.redirect) return <Redirect to='/training' />;
    else if (this.state.isLoading) return <CircularProgress />;
    return this.state.ergonar == null ? null : (
      <Container>
        {/* Header */}
        <div className={classes.header}>

          {/* Image */}
          {this.state.ergonar.getDisplayImageUrl() &&
            <img src={this.state.ergonar.getDisplayImageUrl()} className={classes.image} />
          }

          {/* Title */}
          <div className={classes.titleContainer}>
            <Typography variant='h1' className={classes.title}>{this.state.ergonar.getName()} Ergonar</Typography>
            <Typography variant='subtitle1' style={{ fontSize: '20px', fontWeight: 'bold', textTransform: 'uppercase', color: BLUE }}>Ergonar</Typography>
          </div>
        </div>

        {/* Description */}
        <LineItem
          value={this.state.ergonar.getDescription()}
          description='Description'
        />

        {/* VIDEO ERGONAR */}
        {this.state.ergonar.getType() === VIDEO &&
        <>
          {/* Video Player */}
          {this.state.ergonar.getHasAccess() ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <ReactPlayer
                url={this.state.ergonar.getVideoUrl()}
              />
            </div>
          ) : (
            !this.state.inPurchaseMode ? (
              <Button onClick={() => this.setState({ inPurchaseMode: true })}>Purchase Ergonar</Button>
            ) : (
              <div>
                <PurchaseWidget
                  title='Purchase Ergonar'
                  style={{ marginTop: 30 }}
                  items={[
                    {
                      type: 'Ergonar',
                      id: this.state.ergonar.getId()
                    }
                  ]}
                  onSuccess={this.onPurchaseSuccess}
                  onRedirect={this.onPurchaseRedirect}
                />
              </div>
            )
          )}
        </>}

        {/* ZOOM ERGONAR */}
        {this.state.ergonar.getType() === ZOOM &&
        <>
          {this.state.ergonar.getDateTime() != null && DateTime.now() < this.state.ergonar.getDateTime() &&
          <div>
            {/* Date and Time */}
            <LineItem
              value={this.state.ergonar.getDateTime()?.toLocaleString(FULL_DATE_TIME)}
              description='Date/Time'
            />

            {/* Duration */}
            <LineItem
              value={this.state.ergonar.getEstimatedDuration() ? this.state.ergonar.getEstimatedDuration() + ' min.' : null}
              description='Estimated Duration'
            />

            {this.state.ergonar.getHasRSVP() ? (
              <Typography variant='subtitle1' style={{ fontSize: '20px', fontStyle: 'italic', color: DARK_BLUE }}>You are RSVPd.</Typography>
            ) : (this.state.ergonar.getHasAccess() ? (
              <Button onClick={this.rsvpToErgonar} isLoading={this.state.isLoadingRSVP}>
                Attend Ergonar
              </Button>
            ) : (
              !this.state.inPurchaseMode ? (
                <Button onClick={() => this.setState({ inPurchaseMode: true })}>Purchase Ergonar</Button>
              ) : (
                <div>
                  <PurchaseWidget
                    title='Purchase Ergonar'
                    style={{ marginTop: 30 }}
                    items={[
                      {
                        type: 'Ergonar',
                        id: this.state.ergonar.getId()
                      }
                    ]}
                    onSuccess={this.onPurchaseSuccess}
                    onRedirect={this.onPurchaseRedirect}
                  />
                </div>
              )
            ))}
          </div>}
        </>}
      </Container>
    );
  }
}

Ergonar.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(Ergonar));
