import store from '../redux/store';
import { clearErrors as reduxClearErrors, setError as reduxSetError, setSuccess as reduxSetSuccess, setWarning as reduxSetWarning, setInfo as reduxSetInfo, removeAlert as reduxRemoveAlert, updateAlert as reduxUpdateAlert } from '../redux/actions/alertActions';

export function setSuccess(msg, options = {}) {
  reduxSetSuccess(store?.dispatch, msg, options);
}

export function setError(msg, options = {}) {
  reduxSetError(store?.dispatch, msg, options);
}

export function setWarning(msg, options = {}) {
  reduxSetWarning(store?.dispatch, msg, options);
}

export function setInfo(msg, options = {}) {
  reduxSetInfo(store?.dispatch, msg, options);
}

export function clearErrors() {
  store?.dispatch(reduxClearErrors());
}

export function removeAlert(id) {
  store?.dispatch(reduxRemoveAlert(id));
}

export function updateAlert(id, updates = {}) {
  store?.dispatch(reduxUpdateAlert(id, updates));
}
