/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use to Ergonauts LLC
 */
import { v4 as uuidv4 } from 'uuid';

// Constants
const DEFAULT_TIMEOUT = 5000;

export const ADD_ALERT = 'ADD_ALERT';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CLEAR_INFOS = 'CLEAR_INFOS';
export const REMOVE_ALERT_FROM_MESSAGE = 'REMOVE_ALERT_FROM_MESSAGE';
export const AlertSeverity = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
};

export const addAlert = (msg, severity = AlertSeverity.ERROR, action = { isLoading: false, isComplete: false, onPress: null, label: null }) => ({
  type: ADD_ALERT,
  id: uuidv4(),
  msg,
  severity,
  action
});

export const updateAlert = (id, { msg, severity, action = { isLoading: null, isComplete: null, onPress: null, label: null } }) => ({
  type: UPDATE_ALERT,
  id,
  msg,
  severity,
  action
});

export const removeAlert = (id) => ({
  type: REMOVE_ALERT,
  id
});

export const removeAlertFromMessage = (msg) => ({
  type: REMOVE_ALERT_FROM_MESSAGE,
  msg
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

export const clearAlerts = () => ({
  type: CLEAR_ALERTS
});

export const clearInfos = () => ({
  type: CLEAR_INFOS
});

export const setSuccess = (dispatch, msg, options = {}) => {
  dispatch(clearErrors());
  setAlert(dispatch, msg, { severity: AlertSeverity.SUCCESS, haveTimeout: true, ...options });
};

export const setError = (dispatch, msg, options = {}) => {
  setAlert(dispatch, msg, { severity: AlertSeverity.ERROR, haveTimeout: false, ...options });
};

export const setWarning = (dispatch, msg, options = {}) => {
  setAlert(dispatch, msg, { severity: AlertSeverity.WARNING, haveTimeout: false, ...options });
};

export const setInfo = (dispatch, msg, options = {}) => {
  setAlert(dispatch, msg, { severity: AlertSeverity.INFO, haveTimeout: false, ...options });
};

export const setAlert = (
  dispatch,
  msg,
  {
    severity = AlertSeverity.ERROR,
    haveTimeout = false,
    onCreation = null,
    action: {
      isLoading = false,
      isComplete = false,
      onPress = null,
      label = null
    } = {}
  } = {}
) => {
  window.scrollTo(0, 0);

  if ((msg == null || msg === '') && (msg.message == null || msg.message === '')) {
    switch (severity) {
      case AlertSeverity.ERROR:
        msg = 'Error: something went wrong. Please try again later.';
        break;
      case AlertSeverity.WARNING:
        msg = 'Warning!';
        break;
      case AlertSeverity.SUCCESS:
        msg = 'Success!';
        break;
      default:
        msg = 'An unknown event occurred.';
    }
  }
  else if (msg.message != null) msg = msg.message;

  // Add New Alert to App State
  const newAlert = addAlert(msg, severity, { isLoading, isComplete, onPress, label });
  dispatch(newAlert);
  if (onCreation) onCreation(newAlert);

  // Remove the New Alert After Timeout (5 sec default)
  if (haveTimeout)
    setTimeout(() => dispatch(removeAlert(newAlert.id)), DEFAULT_TIMEOUT);
};
