import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Constants
import { ERGO_BLUE, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

// Components
import { Button, LineItem } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Styling
const styles = theme => ({
  card: {
    width: '100%',
    borderRadius: 8,
    marginBottom: '15px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: ERGO_BLUE,
    color: TEXT_WHITE,
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  body: {
    padding: '20px'
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center'
  },
  button: {
    height: '60px',
    width: '180px',
    backgroundColor: ERGO_BLUE,
    color: TEXT_WHITE
  }
});

// Step Section Component
class EvaluationWidget extends Component {
  getFormattedAddress(address) {
    if (address == null)
      return;
    return (address.getLine2() != null) ? (
      address.getLine1() + ' ' +
      address.getLine2() + ' ' +
      address.getCityStateZip()
    ) : (
      address.getLine1() + ' ' +
      address.getCityStateZip()
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        {/* Header */}
        <div className={classes.header}>
          <Typography variant='h3'>
            Ergo Algo Office
          </Typography>
          <Typography variant='h6'>
            {this.props.evaluation.isComplete() ?
              (this.props.evaluation.getStartTimestamp()?.toLocaleString(FULL_DATE_TIME)) :
              (this.props.evaluation.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME))}
          </Typography>
        </div>

        {/* Body */}
        <div className={classes.body}>
          {this.props.evaluation.isComplete() &&
            <LineItem
              value={this.props.evaluation.getEndTimestamp()?.toLocaleString(FULL_DATE_TIME)}
              description='COMPLETED'
            />
          }
          <LineItem
            value={this.getFormattedAddress(this.props.evaluation.getAddress())}
            description='Address'
          />
          <LineItem
            value={this.props.evaluation.getEvaluator()?.getFullName()}
            description='Evaluator'
          />
          <LineItem
            value={this.props.evaluation.getEvaluator()?.getPhoneNumber()}
            description='Evaluator Phone Number'
          />
          <LineItem
            value={this.props.evaluation.getStatus()}
            description='Status'
          />
          {this.props.evaluation.isComplete() &&
            <div className={classes.buttonContainer}>
              <Button component={Link} to={'/evaluations/' + this.props.evaluation.getId()} size="large" className={classes.button}>View Results</Button>
            </div>
          }
        </div>
      </Card>
    );
  }
}
EvaluationWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  evaluation: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EvaluationWidget);
