/*
* Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
* Licensed for non-distributable use to Ergonauts LLC
*/
import { Container, Typography } from '@mui/material';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Oops... Looks like something went wrong!</Typography>
        <img height='400px' src="https://ergoalgoimages.blob.core.windows.net/images/error_plugs.png"></img>
        <Typography variant='subtitle1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Please refresh and try again.</Typography>
      </Container>
    );
  }
}

ErrorPage.propTypes = {
};

export default withRouter(ErrorPage);
