/*
* Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
* Licensed for non-distributable use to Ergonauts LLC
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, CircularProgress } from '@mui/material';

// Redux
import { connect } from 'react-redux';
import { removeAlert } from '../redux/actions/alertActions';

class AlertList extends Component {
  render() {
    return (
      <>
        {this.props.alerts?.map((alert, index) => (
          <Alert
            key={index}
            variant='filled'
            severity={alert.severity}
            onClose={(alert.action?.onPress == null || alert.action?.isComplete) ? () => this.props.removeAlert(alert.id) : null}
            action={
              alert.action?.onPress != null && !alert.action?.isComplete ? (
                <Button color='inherit' variant='text' onClick={alert.action.onPress} disabled={alert.action?.isLoading} style={{ textTransform: 'uppercase' }}>
                  {alert.action?.isLoading ? (
                    <CircularProgress color='inherit' style={{ width: '20px', height: '20px' }} />
                  ) : (
                    alert.action?.label
                  )}
                </Button>
              ) : null
            }
            style={{ fontWeight: alert.severity === 'error' ? 'bold' : 'normal', minHeight: alert.severity === 'error' ? '50px' : '0px', alignItems: 'center' }}>
            {alert.msg}
          </Alert>
        ))}
      </>
    );
  }
}

AlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

const mapDispatchToProps = dispatch => ({
  removeAlert: id => dispatch(removeAlert(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);
