import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Api
import { fullyAuthenticatePerson } from '../../../redux/actions/authActions';
import { sendTwoFactorVerification } from '../../../api';

// Alerts
import { setError, setSuccess, clearErrors } from '../../../alerts';

// Components
import { Container, Typography, TextField, Tooltip } from '@mui/material';
import { Button } from '@lexcelon/react-util';

class TwoFactor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationType: '',
      selectedOption: 'SMS',
      apiCall: null,
      sentMessage: '',
      authCode: '',
      isLoadingResend: false,
      disableResend: true
    };
  }

  componentDidMount() {
    this.sendVerification();
  }

  componentWillUnmount() {
    if (this.resendTimer != null) clearTimeout(this.resendTimer);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendVerification = (e, isResend = false) => {
    if (e != null) e.preventDefault();

    if (isResend) this.setState({ isLoadingResend: true });

    let sentMessage = null;
    let resendMessage = null;
    let errorMessage = null;
    switch (this.state.selectedOption) {
      case 'SMS':
        sentMessage = `Text message containing verification code sent to ${this.props.phoneNumber}.`;
        resendMessage = `Successfully resent verification code to ${this.props.phoneNumber}.`;
        errorMessage = 'Error: Could not send text message.';
        break;
      case 'CALL':
        sentMessage = `Call containing verification code made to ${this.props.phoneNumber}.`;
        resendMessage = `Successfully resent verification code to ${this.props.phoneNumber}.`;
        errorMessage = 'Error: Could not make call.';
        break;
      // case 'EMAIL':
      //   sentMessage =  `Email containing verification code sent to ${this.props.email}.`;
      //   resendMessage = `Successfully resent verification code to ${this.props.email}.`;
      //   errorMessage = 'Error: Could not send email.';
      //   break;
    }

    sendTwoFactorVerification(this.state.selectedOption).then(() => {
      this.setState({ isLoadingResend: false, verificationType: this.state.selectedOption, sentMessage, disableResend: true });
      if (isResend) setSuccess(resendMessage);
      this.resendTimer = setTimeout(() => this.setState({ disableResend: false }), 120000);
    }).catch((error) => {
      setError(error ?? errorMessage);
      this.setState({ isLoadingResend: false });
      if (error.includes('2 minutes')) this.resendTimer = setTimeout(() => {
        this.setState({ disableResend: false });
        clearErrors();
      }, 120000);
    });
  }

  onVerificationSubmit = (e) => {
    e.preventDefault();

    let strippedAuthCode = this.state.authCode.replace(/\s/g, '');
    this.props.fullyAuthenticatePerson(strippedAuthCode);
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Two-Factor Authentication</Typography>

        <Typography style={{ fontSize: '24px', textAlign: 'center' }}>{this.state.sentMessage} Please enter your verification code below.</Typography>
        <form onSubmit={this.onVerificationSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <TextField
            required
            name='authCode'
            label='Verification Code'
            style={{ margin: '2em', width: 300 }}
            onChange={this.onChange}
            variant='filled'
            disabled={this.props.isLoadingVerification || this.state.isLoadingResend}
          />

          <Button type='submit' style={{ width: 300, marginTop: '0' }} isLoading={this.props.isLoadingVerification} disabled={this.state.isLoadingResend }>Submit</Button>

          <Tooltip title={this.state.disableResend ? 'Must wait 2 minutes before resending.' : ''}>
            <span>
              <Button secondary onClick={(e) => this.sendVerification(e, true)} isLoading={this.state.isLoadingResend} disabled={this.props.isLoadingVerification || this.state.disableResend } style={{ width: 275, marginTop: '2em' }}>Resend verification code</Button>
            </span>
          </Tooltip>
        </form>
      </Container >
    );
  }
}

TwoFactor.propTypes = {
  fullyAuthenticatePerson: PropTypes.func.isRequired,
  isLoadingVerification: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  twoFactorType: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isLoadingVerification: state.auth.isLoading,
  email: state.auth.email,
  phoneNumber: state.auth.phoneNumber,
  twoFactorType: state.auth.twoFactorType
});

const mapDispatchToProps = (dispatch) => ({
  fullyAuthenticatePerson: (token) => fullyAuthenticatePerson(dispatch, token)
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactor);
