import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Card, CardContent, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Alerts
import { setError } from '../alerts';

// Constants
import { DARK_BLUE, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';
import { listUpcomingErgonars } from '../api';

// Components
import ErgonarWidget from './ErgonarWidget';

const styles = theme => ({
  title: {
    color: TEXT_WHITE,
    textAlign: 'center',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30
    }
  }
});

class UpcomingErgonarsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ergonars: []
    };
  }

  componentDidMount(){
    listUpcomingErgonars().then(({ results }) => {
      this.setState({ ergonars: results });
    }).catch(() => {
      setError('Error: unable to load upcoming Ergonars');
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Card style={{ backgroundColor: DARK_BLUE, width: '100%', borderRadius: 8 }}>
        <Link to='/training/upcoming-ergonars' style={{ textDecoration: 'none' }}>
          <Typography variant='h2' className={classes.title}>Upcoming Ergonars</Typography>
        </Link>

        {/* Filter and Search */}
        <AppBar position='static' style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
          {/* <Fab variant='extended'>
            Filter
            <AddIcon style={{ paddingLeft: '10px' }} />
          </Fab>

          <Fab variant='extended' style={{ flex: 1, marginLeft: '10px' }}>
            <InputBase
              style={{ marginLeft: '10px', flex: 1 }}
              placeholder='Search'
              inputProps={{ 'aria-label': 'search list' }}
            />
            <SearchIcon />
          </Fab> */}
        </AppBar>

        {/* Items */}
        <CardContent style={{ overflow: 'auto', height: this.props.height ?? '75vh' }}>
          {this.state.ergonars.map((ergonar, index) => {
            return <ErgonarWidget key={index} ergonar={ergonar} />;
          })}
        </CardContent>
      </Card>
    );
  }
}

UpcomingErgonarsWidget.propTypes = {
  height: PropTypes.number,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(UpcomingErgonarsWidget);
