import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Container, Typography } from '@mui/material';

// Components
import { EvaluationForm } from '../../../components';

class ScheduleEvaluation extends Component {
  constructor(props) {
    super(props);

    this.groupId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.groupId;
    this.evaluatorId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.evaluatorId;
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Schedule Evaluation</Typography>

        <EvaluationForm
          evaluatorId={this.evaluatorId}
          groupId={this.groupId}
        />
      </Container>
    );
  }
}

ScheduleEvaluation.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(ScheduleEvaluation);
