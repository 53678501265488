import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Container, Checkbox, FormControlLabel, Typography, TextField, MenuItem, FormHelperText, RadioGroup, Radio } from '@mui/material';
import qs from 'qs';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Components
import { Button } from '@lexcelon/react-util';
import AddressInput from '../../../components/AddressInput';

// Redux
import { connect } from 'react-redux';
import { setError, setSuccess } from '../../../alerts';

// Images
import { ColorLogo } from '@ergonauts/ergo-algo-react/core/images';

// API
import { createPerson } from '../../../api';

// Constants
import { GENDER_OPTIONS, BLUE } from '@ergonauts/ergo-algo-react/core/constants';

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      gender: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      password: '',
      confirmPassword: '',
      agreeToConditions: false,
      agreeToUS: false,
      receiveMarketingEmails: false,
      isLoading: false,
      evaluatorInterest: false,
      heardAboutFrom: '',
      otherText: ''
    };

    this.fillInAddress = this.fillInAddress.bind(this);
  }

  componentDidMount() {
    if (window && window.google) {
      var address1Field = document.querySelector('#line1');
      this.setState({
        autocomplete: new window.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components'],
          types: ['address'],
        })
      }, () => {
        this.state.autocomplete.addListener('place_changed', this.fillInAddress);
      });
    }

    let email = qs.parse(location.search, { ignoreQueryPrefix: true })?.email;
    if (email != null) {
      this.setState({
        email: email
      });
    }
  }

  fillInAddress() {
    if (this.state.autocomplete) {
      // Get the place details from the autocomplete object.
      const place = this.state.autocomplete.getPlace();
      let address1 = '';
      var city = '';
      var state = '';
      var zip = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code':
            zip = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          default:
        }
      }

      this.setState({ address: { line1: address1, city: city, state: state, zip: zip } });

      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      var address2Field = document.querySelector('#line2');
      address2Field.focus();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      address,
      password,
      confirmPassword,
      evaluatorInterest,
      heardAboutFrom
    } = this.state;

    // Replace empty strings with null
    address.line2 = address.line2 === '' ? null : address.line2;

    // Make sure passwords match
    if (password !== confirmPassword) {
      setError('Error: Passwords must match');
      return;
    }
    else if (heardAboutFrom?.length > 200) {
      setError('Please adjust how you heard about us to under 200 characters.');
      return;
    }

    this.setState({ isLoading: true });
    createPerson({ firstName, lastName, phoneNumber, email, gender, address, password, evaluatorInterest, heardAboutFrom }).then(() => {
      this.setState({ isLoading: false });
      setSuccess('Successfully created account. Please check your email for a confirmation link to log in. If you do not receive an email from us after 5 minutes, check your Spam and Junk folders.');
      this.setState({ redirectToLogin: true });
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error);
    });
  };

  render() {
    return this.state.redirectToLogin ? <Redirect to='/auth/login' /> : (
      <Container>
        <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
          {/* ----- LOGO & TITLE ----- */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em', marginBottom: '1em' }}>
            <img
              width={250}
              src={ColorLogo}
              alt={'Ergonauts Logo'}
              title={'Ergonauts Logo'}
              style={{ objectFit: 'contain' }}
            />
          </div>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em' }}>Create an Account</Typography>

          {/* ----- PERSONAL INFORMATION ----- */}
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Personal Information</Typography>

          {/* Name Input */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
            <TextField required disabled={this.state.isLoading} error={this.state.formError && this.state.firstName === ''} name="firstName" label="First Name" style={{ width: '49%' }} value={this.state.firstName} onChange={this.onChange} variant='filled' />
            <TextField required disabled={this.state.isLoading} error={this.state.formError && this.state.lastName === ''} name="lastName" label="Last Name" style={{ width: '49%' }} value={this.state.lastName} onChange={this.onChange} variant='filled' />
          </div>

          {/* Email Input */}
          <TextField
            required
            disabled={this.state.isLoading}
            error={this.state.formError && this.state.email === ''}
            name="email"
            label="Email"
            type='email'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
          />

          {/* Phone Number */}
          <ReactPhoneInput
            country='us'
            onlyCountries={['us']}
            value={this.state.phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
            component={TextField}
            inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading }}
            disableCountryCode={true}
            disableDropdown={true}
            placeholder='(702) 123-4567'
            containerStyle={{ width: '100%', marginBottom: '10px' }}
          />

          {/* Gender */}
          <TextField
            required
            select
            disabled={this.state.isLoading}
            error={this.state.formError && this.state.gender === ''}
            name="gender"
            label="Gender"
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.gender}
            onChange={this.onChange}
            variant='filled'>
            {GENDER_OPTIONS.map((gender, i) => (
              <MenuItem key={i} value={gender.enum}>
                {gender.display}
              </MenuItem>
            ))}
          </TextField>

          <div style={{ marginTop: '30px' }}>
            <Typography>How did you hear about us?</Typography>
            <Container style={{ display: 'flex', flexDirection: 'column' }} >
              <RadioGroup
                value={this.state.heardAboutFrom}
                name='heardAboutFrom'
                onChange={this.onChange}>
                <FormControlLabel disabled={this.state.isLoading} value='Internet Search' control={<Radio />} label='Internet Search' />
                <FormControlLabel disabled={this.state.isLoading} value='Personnel/Professional Reference' control={<Radio />} label='Personnel/Professional Reference' />
                <FormControlLabel disabled={this.state.isLoading} value='LinkedIn' control={<Radio />} label='LinkedIn' />
                <FormControlLabel disabled={this.state.isLoading} value='Twitter' control={<Radio />} label='Twitter' />
                <FormControlLabel disabled={this.state.isLoading} value='Facebook' control={<Radio />} label='Facebook' />
                <FormControlLabel disabled={this.state.isLoading} value='AAOHN' control={<Radio />} label='AAOHN – American Association of Occupational Health Nurses' />
                <FormControlLabel disabled={this.state.isLoading} value='ANA' control={<Radio />} label='ANA – American Nurses Association' />
                <FormControlLabel disabled={this.state.isLoading} value='AOTA' control={<Radio />} label='AOTA – American Occupational Therapy Association' />
                <FormControlLabel disabled={this.state.isLoading} value='APTA' control={<Radio />} label='APTA – American Physical Therapy Association' />
                <FormControlLabel disabled={this.state.isLoading} value='OMA' control={<Radio />} label='OMA – Office Moving Alliance' />
                <FormControlLabel disabled={this.state.isLoading} value='ASSP' control={<Radio />} label='ASSP – American Society of Safety Professionals' />
                <FormControlLabel disabled={this.state.isLoading} value='NATA' control={<Radio />} label='NATA – National Association of Athletic Training' />
                <FormControlLabel disabled={this.state.isLoading} value='VPPPA' control={<Radio />} label='VPPPA – Voluntary Protection Programs Participants Association' />
                <FormControlLabel disabled={this.state.isLoading} value='ACA' control={<Radio />} label='ACA – American Chiropractor Association' />
                <FormControlLabel disabled={this.state.isLoading} value='ICA' control={<Radio />} label='ICA – International Chiropractic Association' />
                <FormControlLabel disabled={this.state.isLoading} value='AIHA' control={<Radio />} label='AIHA – American Industrial Hygiene Association' />
                <FormControlLabel disabled={this.state.isLoading} value='IDSA' control={<Radio />} label='IDSA – Industrial Design Society of America' />
                <FormControlLabel disabled={this.state.isLoading} value='RMA' control={<Radio />} label='RMA – Risk Management Association' />
                <FormControlLabel disabled={this.state.isLoading} value='IEEE' control={<Radio />} label='IEEE – International Electrical Electronic Engineers' />
                <FormControlLabel disabled={this.state.isLoading} value='AMTA' control={<Radio />} label='AMTA – American Massage Therapy Association' />
                <FormControlLabel disabled={this.state.isLoading} value='ACMA' control={<Radio />} label='ACMA – American Case Management Association' />
                <FormControlLabel disabled={this.state.isLoading} value='HFES' control={<Radio />} label='HFES – Human Factors Ergonomics Society' />
                <FormControlLabel disabled={this.state.isLoading} value='NSC' control={<Radio />} label='NSC – National Safety Council' />
                <FormControlLabel disabled={this.state.isLoading} value='Airrosti' control={<Radio />} label='Airrosti' />
                <FormControlLabel disabled={this.state.isLoading} value='ISDA' control={<Radio />} label='ISDA – Industrial Design Society of America' />
                <FormControlLabel disabled={this.state.isLoading} value='ACOEM' control={<Radio />} label='ACOEM – American College of Occupational Environmental Medicine' />
                <FormControlLabel disabled={this.state.isLoading} value='IFMA' control={<Radio />} label='IFMA – International Facilities Management Association' />
                <FormControlLabel disabled={this.state.isLoading} value={this.state.otherText} control={<Radio />} label={ this.state.otherText === this.state.heardAboutFrom ? (
                  <TextField
                    name='otherText'
                    label='Other'
                    value={this.state.otherText}
                    required={this.state.otherText === this.state.heardAboutFrom}
                    onChange={(e) => {
                      this.setState({ otherText: e.target.value });
                      this.setState({ heardAboutFrom: e.target.value });
                    }}
                    disabled={this.state.isLoading}
                    onFocus={() => this.setState({ heardAboutFrom: this.state.otherText })}
                  /> ) : 'Other'
                } />
              </RadioGroup>
            </Container>
          </div>

          {/* ----- MAILING ADDRESS ----- */}
          <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Mailing Address</Typography>

          <AddressInput
            isLoading={this.state.isLoading}
            onChange={(address) => this.setState({ address })}
            requiredFields={['line1', 'city', 'state', 'zip']}
          />
          <FormHelperText>Our products and services are currently only available in the United States.</FormHelperText>

          {/* ----- CREATE PASSWORD ----- */}
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Create Password</Typography>
          <TextField
            required
            disabled={this.state.isLoading}
            error={this.state.formError && this.state.password === ''}
            name="password"
            label="Password"
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.password}
            onChange={this.onChange}
            variant='filled'
            type='password'
          />
          <TextField
            required
            disabled={this.state.isLoading}
            error={this.state.formError && this.state.confirmPassword === ''}
            name="confirmPassword"
            label="Confirm Password"
            style={{ width: '100%', marginBottom: '20px' }}
            value={this.state.confirmPassword}
            onChange={this.onChange}
            variant='filled'
            type='password'
          />
          <FormHelperText sx={{ mb: '2rem' }}>
            Password must be between 8-30 characters long with at least one uppercase letter, one lowercase letter, one number, and one symbol.
          </FormHelperText>

          {/* Accept terms and conditions */}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginBottom: '20px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  disabled={this.state.isLoading}
                  color='primary'
                  checked={this.state.agreeToUS}
                  onChange={this.onCheckboxChange}
                  name="agreeToUS"
                />
              }
              label={<Typography>I understand this product is licensed to be used in the US only. *</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  required
                  disabled={this.state.isLoading}
                  color='primary'
                  checked={this.state.agreeToConditions}
                  onChange={this.onCheckboxChange}
                  name="agreeToConditions"
                />
              }
              label={<Typography>I agree to the terms and conditions outlined in the <a href='https://ergonauts.com/assets/eula.pdf' target="_blank" rel="noopener noreferrer" style={{ color: BLUE }}>End User License Agreement (EULA)</a>, <a href='https://ergonauts.com/assets/services-agreement.pdf' target="_blank" rel="noopener noreferrer" style={{ color: BLUE }}>Services Agreement</a>, and <a href='https://ergonauts.com/assets/privacy-policy.pdf' target="_blank" rel="noopener noreferrer" style={{ color: BLUE }}>Privacy Policy</a>. *
              </Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  required
                  disabled={this.state.isLoading}
                  color='primary'
                  checked={this.state.receiveMarketingEmails}
                  onChange={this.onCheckboxChange}
                  name="receiveMarketingEmails"
                />
              }
              label={<Typography>I agree to receive ongoing communications from Ergonauts.*
              </Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={this.state.isLoading}
                  color='primary'
                  checked={this.state.evaluatorInterest}
                  onChange={this.onCheckboxChange}
                  name="evaluatorInterest"
                />
              }
              label={<Typography>I would like to receive more information on becoming an Ergonaut™ Evaluator.{' '}
              </Typography>}
            />


          </div>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
            <Button type='submit' isLoading={this.state.isLoading || this.props.isLoading} disabled={this.state.error} style={{ width: '50%' }}>
              Create Account
            </Button>
            <Typography variant='button' style={{ marginTop: '10px' }}>Already have an account?{' '}
              <Link to="/auth/login" style={{ color: BLUE }}>
                Log in now
              </Link>.
            </Typography>
          </div>
        </form>
      </Container>
    );
  }
}

CreateAccount.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading
});

export default connect(mapStateToProps, null)(CreateAccount);
